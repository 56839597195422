const actions = {
  LOAD_ASYNC: 'payMethod/LOAD_ASYNC',
  LOAD: 'payMethod/LOAD',
  SET_DATA: 'payMethod/SET_DATA',
  LOAD_COUNTRY: 'payMethod/LOAD_COUNTRY',
  SET_COUNTRY: 'payMethod/SET_COUNTRY',
  LOAD_DEPTO: 'payMethod/LOAD_DEPTO',
  SET_DEPTO: 'payMethod/SET_DEPTO',
  LOAD_MUNICIPALITY: 'payMethod/LOAD_MUNICIPALITY',
  SET_MUNICIPALITY: 'payMethod/SET_MUNICIPALITY',
  LOAD_ADDRESS: 'payMethod/LOAD_ADDRESS',
  SET_ADDRESS: 'payMethod/SET_ADDRESS',
}

export const payMethodCommerce = data => ({
  type: actions.LOAD_ASYNC,
  payload: data,
})

export const setPayMethodData = data => ({
  type: actions.SET_DATA,
  payload: data,
})

/** Campos de dirección para tarjeta de crédito **/
export const loadCountryTC = data => ({
  type: actions.LOAD_COUNTRY,
  payload: data,
})

export const setCountryTC = data => ({
  type: actions.SET_COUNTRY,
  payload: data,
})

export const loadDeptoTC = data => ({
  type: actions.LOAD_DEPTO,
  payload: data,
})

export const setDeptoTC = data => ({
  type: actions.SET_DEPTO,
  payload: data,
})

export const loadMunicipalityTC = data => ({
  type: actions.LOAD_MUNICIPALITY,
  payload: data,
})

export const setMunicipalityTC = data => ({
  type: actions.SET_MUNICIPALITY,
  payload: data,
})

export const loadAddressTC = data => ({
  type: actions.LOAD_ADDRESS,
  payload: data,
})

export const setAddressTC = data => ({
  type: actions.SET_ADDRESS,
  payload: data,
})

export default actions
