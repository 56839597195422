import { privateAxios } from 'services'
import endpoints from 'constants/endpoints'

const qs = require('qs')

export const tableTransactions = parametros =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.TRANSACTIONS.TABLE_TRANSACTIONS}`,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export default {
  tableTransactions,
}
