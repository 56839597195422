import actions from './actions'

const initialState = {
  tableLoading: false,
  tableTransactions: {},
}

export default function useReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.TABLE_TRANSACTIONS: {
      const { payload = {} } = action
      return {
        ...state,
        tableTransactions: payload,
      }
    }
    default:
      return state
  }
}
