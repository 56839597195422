import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  getCategories,
  createCategory,
  updateCategory,
  updateCategoryOrder,
  deleteCategory,
} from 'services/categories'
import { SITE_MESSAGE } from 'constants/site_message'
import store from 'models/store'
import { safeSagaRequest } from 'helpers/safeSaga'
import { isEmpty } from 'helpers/isEmpty'
import { ACTION_configLoading } from 'models/redux/config/actions'
import actions, { ACTION_SAGA_CategoriesGet } from './actions'

// PUBLIC
export function* GET_CATEGORIES() {
  /** Prevent reloading */
  const { categories } = store.getState()
  if (!isEmpty(categories.categories)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(getCategories)
  yield put(ACTION_SAGA_CategoriesGet(success.data))
  yield put(
    ACTION_configLoading({
      loading: false,
      message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
    }),
  )
}

export function createCategorySaga(data) {
  return createCategory(data)
}

export function editCategorySaga(data) {
  return updateCategory(data)
}

export function deleteCategorySaga(data) {
  return deleteCategory(data)
}

export function updateCategoriesOrder(data) {
  return updateCategoryOrder(data)
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.LOAD_ASYNC_CATEGORIES,
      safeSagaRequest(GET_CATEGORIES, actions.LOAD_ASYNC_CATEGORIES),
    ),
  ])
}
