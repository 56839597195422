import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadPublicCommerceAll,
  loadPublicCommerceCategories,
  createCommerce,
  loadPrivateCommerces,
  loadPrivateCommercesById,
  updateCommerceCategory,
  listCommercesDeletedService,
  tableLogsService,
  loadListCountries,
  createListExcluded,
  loadListExcludeCountries,
} from 'services/commerce'
import { SITE_MESSAGE } from 'constants/site_message'
import store from 'models/store'
import { safeSagaRequest } from 'helpers/safeSaga'
import { isEmpty } from 'helpers/isEmpty'
import { categorize } from 'factories/categorize'
import { ACTION_configLoading } from 'models/redux/config/actions'
import actions, {
  ACTION_SAGA_loadCommerce,
  ACTION_SAGA_loadCategories,
  ACTION_SAGA_loadCategorizedCommerce,
  ACTION_SAGA_loadPrivateCommerces,
  ACTION_SAGA_listCommercesDeleted,
  ACTION_SAGA_tableLogs,
  ACTION_SAGA_loadListCountries,
  ACTION_SAGA_loadListExcludedCountries,
} from './actions'

// PRIVATE
export function* LOAD_PRIVATE_COMMERCES() {
  /** Prevent reloading */
  const { commerce } = store.getState()
  if (!isEmpty(commerce.private)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadPrivateCommerces)
  if (success.data && success.data.length === 0) {
    success.data[0] = {}
  }
  yield put(ACTION_SAGA_loadPrivateCommerces(success.data))
  yield put(
    ACTION_configLoading({
      loading: false,
      message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
    }),
  )
}

// Listado de comercios eliminados
export function* SAGAS_LIST_COMMERCES_DELETED() {
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(listCommercesDeletedService)
  yield put({
    type: ACTION_SAGA_listCommercesDeleted,
    payload: { commercesDeleted: success.data },
  })
}

// PUBLIC

export function* LOAD_ASYNC() {
  /** Prevent reloading */
  const { commerce } = store.getState()
  if (!isEmpty(commerce.public)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadPublicCommerceAll)
  yield put(ACTION_SAGA_loadCommerce(success.data))
  yield put(
    ACTION_configLoading({
      loading: false,
      message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
    }),
  )
}

export function* LOAD_CATEGORIES() {
  /** Prevent reloading */
  const { commerce } = store.getState()
  if (!isEmpty(commerce.categories)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadPublicCommerceCategories)
  yield put(ACTION_SAGA_loadCategories(success.data))
  yield put(
    ACTION_configLoading({
      loading: false,
      message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
    }),
  )
}

export function* LOAD_COMMERCE_CATEGORIES() {
  /** Prevent reloading */
  const { commerce } = store.getState()
  if (!isEmpty(commerce.categories) && !isEmpty(commerce.public)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const [fetchCommerce, fetchCategories] = yield all([
    call(loadPublicCommerceAll),
    call(loadPublicCommerceCategories),
  ])
  yield all([
    put(ACTION_SAGA_loadCommerce(fetchCommerce.data)),
    put(ACTION_SAGA_loadCategories(fetchCategories.data)),
  ])
  const grouped = categorize(fetchCommerce.data, fetchCategories.data)
  yield put(ACTION_SAGA_loadCategorizedCommerce(grouped))
  yield put(
    ACTION_configLoading({
      loading: false,
      message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
    }),
  )
}

export function createCommerceSaga(datos) {
  const comercio = datos
  comercio.city = { ID: datos.city }
  comercio.document_type = { ID: datos.tipoDoc }
  comercio.document_number = datos.numDoc
  comercio.ticket_type = { ID: datos.ticket_type }
  comercio.sub_type = { ID: datos.sub_typeID }
  const metodos = []
  datos.payment_method.forEach(function a(value) {
    metodos.push({ ID: value })
  })
  comercio.payment_method = metodos
  const contactos = []
  if (datos.contactos) {
    datos.contactos.forEach(function con(element) {
      contactos.push({
        name: datos.names[element],
        area: datos.areas[element],
        phone: datos.telefonos[element],
        email: datos.correos[element],
      })
    })
    comercio.names = null
    comercio.areas = null
    comercio.telefonos = null
    comercio.correos = null
    comercio.contactos = null
  }
  comercio.contacts = contactos
  comercio.agreement.payment_cycle = { ID: datos.agreement.payment_cycle }
  comercio.agreement.account_name = datos.agreement.account_name
  comercio.agreement.account_type = { ID: datos.agreement.account_type }
  comercio.agreement.account_number = datos.agreement.account_number
  comercio.agreement.service_type = { ID: datos.agreement.service_type }
  comercio.agreement.pse_maximum_amount = Number(datos.agreement.pse_maximum_amount)
  comercio.agreement.pse_maximum_amount_jur = Number(datos.agreement.pse_maximum_amount_jur)
  comercio.agreement.tc_maximum_amount = Number(datos.agreement.tc_maximum_amount)
  comercio.agreement.number_transactions = Number(datos.agreement.number_transactions)
  comercio.agreement.min_number_transactions = Number(datos.agreement.min_number_transactions)
  comercio.agreement.transaction_amount = Number(datos.agreement.transaction_amount)
  comercio.agreement.pse_code = datos.agreement.psecode
  comercio.agreement.total_transaction_amount = 0
  comercio.image = datos.commerce_image
  comercio.category = { ID: datos.category }

  const conceptos = []
  console.log(datos.keyPaymentConcept)
  if (datos.keyPaymentConcept && datos.paymentAmount && datos.paymentAmount) {
    datos.keyPaymentConcept.forEach(function con(element) {
      const montoCon = datos.paymentAmount ? Number(datos.paymentAmount[element]) : 0
      const nameCon = datos.paymentConcept ? datos.paymentConcept[element] : ''
      conceptos.push({
        name: nameCon,
        amount: montoCon,
      })
    })
    comercio.keyPaymentConcept = null
    comercio.paymentConcept = null
    comercio.paymentAmount = null
  }
  comercio.payment_concept = conceptos
  comercio.free_payment_concept = datos.free_payment_concept === '1'

  const formField = {}
  if (datos.keyExtraData) {
    datos.keyExtraData.forEach(function con(element) {
      formField[datos.extraDataName[element]] = {}
      formField[datos.extraDataName[element]].type = datos.extraDataType[element]
      formField[datos.extraDataName[element]].label = datos.extraDataLabel[element]
      formField[datos.extraDataName[element]].tooltip = datos.extraDataTooltip[element]
      if (datos.extraDataList) {
        formField[datos.extraDataName[element]].list = datos.extraDataList[element]
      }
      if (datos.extraDataLiquidador) {
        formField[datos.extraDataName[element]].liquidador = datos.extraDataLiquidador[element]
      }
      if (datos.extraDataDependiente) {
        formField[datos.extraDataName[element]].dependiente = datos.extraDataDependiente[element]
      }
    })
    comercio.keyExtraData = null
    comercio.extraDataName = null
    datos.extraDataType = null
    datos.extraDataLabel = null
    datos.extraDataTooltip = null
    datos.extraDataList = null
    datos.extraDataLiquidador = null
    datos.extraDataDependiente = null
  }
  comercio.form_field = formField
  comercio.commerce_url = datos.url_redirect
  comercio.tipoDoc = null
  comercio.numDoc = null
  comercio.document_anti_fraud_name = datos.agreement.document_anti_fraud_name
  comercio.document_anti_fraud_url = datos.agreement.document_anti_fraud_url
  comercio.document_network2_name = datos.agreement.document_network2_name
  comercio.document_network2_url = datos.agreement.document_network2_url
  comercio.document_network_name = datos.agreement.document_network_name
  comercio.document_network_url = datos.agreement.document_network_url
  comercio.label_ticket = datos.label_ticket
  comercio.ticket_key = datos.ticket_key === '1'
  comercio.multi_ticket = datos.multi_ticket === '1'
  comercio.ticket_expired = datos.ticket_expired === '1'
  comercio.ticket_less = datos.ticket_less
  comercio.ticket_more = datos.ticket_more
  comercio.label_reference = datos.label_reference
  comercio.label_amount = datos.label_amount
  comercio.label_concept = datos.label_concept
  comercio.help_reference = datos.help_reference
  comercio.help_amount = datos.help_amount
  comercio.help_concept = datos.help_concept
  comercio.type_reference = datos.type_reference
  comercio.type_concept = datos.type_concept
  comercio.concept_list = datos.concept_list === '1'
  comercio.payment_parcial = datos.payment_parcial === '1'
  comercio.not_visible = datos.not_visible
  comercio.currencies = datos.currencies
  comercio.option_ticket = datos.option_ticket


  return createCommerce(comercio)
}

export function* getListLogs() {
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(tableLogsService)
  yield put({
    type: ACTION_SAGA_tableLogs,
    payload: { listLogs: success.data },
  })
}
export function* getListCountries() {
  /** Prevent reloading */
  const { commerce } = store.getState()
  if (!isEmpty(commerce.listCountries)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadListCountries)
  yield put({
    type: ACTION_SAGA_loadListCountries,
    payload: { listCountries: success.data },
  })
  yield put(
    ACTION_configLoading({
      loading: false,
      message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
    }),
  )
}

export function* UPDATE_LIST_EXCLUDE_COUNTRIES({ payload: data }) {
  yield put({
    type: actions.UPDATE_LIST_EXCLUDE_COUNTRIES,
    payload: { data },
  })
  try {
    const succes = yield call(createListExcluded, data)
    if (succes.data) {
      console.log(data)
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: actions.UPDATE_LIST_EXCLUDE_COUNTRIES,
      payload: { success: false, loading: false, onError: true },
    })
  }
}

export function* LOAD_LIST_EXCLUDE_COUNTRIES(data) {
  const { commerce } = store.getState()
  if (!isEmpty(commerce.excludeCountries)) return
  yield put(ACTION_configLoading({ loading: true }))
  const succes = yield call(loadListExcludeCountries, data)
  yield put({
    type: ACTION_SAGA_loadListExcludedCountries,
    payload: { countriesExcluded: succes.data },
  })
}

export function getCommercesByCategoryId(data) {
  return loadPrivateCommercesById(data)
}

export function linkCommerceToCategory(data) {
  return updateCommerceCategory(data)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ASYNC, safeSagaRequest(LOAD_ASYNC, actions.LOAD_ASYNC)),
    takeEvery(
      actions.LOAD_ASYNC_CATEGORY,
      safeSagaRequest(LOAD_CATEGORIES, actions.LOAD_ASYNC_CATEGORY),
    ),
    takeEvery(
      actions.LOAD_ASYNC_COMMERCE_CATEGORY,
      safeSagaRequest(LOAD_COMMERCE_CATEGORIES, actions.LOAD_ASYNC_COMMERCE_CATEGORY),
    ),
    takeEvery(
      actions.LOAD_ASYNC_PRIVATE_COMMERCES,
      safeSagaRequest(LOAD_PRIVATE_COMMERCES, actions.LOAD_ASYNC_PRIVATE_COMMERCES),
    ),
    takeEvery(
      actions.LOAD_LIST_COMMERCES_DELETED,
      safeSagaRequest(SAGAS_LIST_COMMERCES_DELETED, actions.LOAD_LIST_COMMERCES_DELETED),
    ),
    takeEvery(
      actions.LOAD_LIST_TABLE_LOGS,
      safeSagaRequest(getListLogs, actions.LOAD_LIST_TABLE_LOGS),
    ),
    takeEvery(
      actions.LOAD_LIST_COUNTRIES,
      safeSagaRequest(getListCountries, actions.LOAD_LIST_COUNTRIES),
    ),
    takeEvery(
      actions.UPDATE_LIST_EXCLUDE_COUNTRIES,
      safeSagaRequest(UPDATE_LIST_EXCLUDE_COUNTRIES, actions.UPDATE_LIST_EXCLUDE_COUNTRIES),
    ),
    takeEvery(
      actions.LOAD_LIST_EXCLUDE_COUNTRIES,
      safeSagaRequest(LOAD_LIST_EXCLUDE_COUNTRIES, actions.LOAD_LIST_EXCLUDE_COUNTRIES),
    ),
  ])
}
