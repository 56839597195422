import { publicAxios } from 'services'
import endpoints from 'constants/endpoints'
//import { encryptedRequest } from 'utils/encrypted'

export function payTicketData(datos) {
  //const encryptedData = encryptedRequest(datos)
  return publicAxios().request({
    method: 'post',
    url: endpoints.PAY.TICKET,
    //data: { data: encryptedData },
    data: datos,
  })
}

export default { payTicketData }
