import actions from './actions'

const initialState = {
  errorMessages: [],
  tableRetriesFilters: [],
  tableTransactions: null,
  integration: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ERROR_MESSAGES: {
      const { payload = [] } = action
      return {
        ...state,
        errorMessages: payload,
      }
    }
    case actions.LOAD: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.SET_INTEGRATION: {
      const { payload = {} } = action
      return {
        ...state,
        integration: payload,
      }
    }
    default:
      return state
  }
}
