export const CODE_ROLE = {
  ADMIN: 'admin',
  COMMERCE: 'commerce',
  ADMIN_COMMERCE: 'commerce_admin',
  PERFIL_DAVIVIENDA: 'davivienda',
  PERFIL_CALLCENTER: 'callcenter',
  PERFIL_SUPERCOMERCIO: 'supercomercio',
  PERFIL_ADMINCOMMERCE: 'admin-comercio',
}

export const CODE_TYPE_TICKET = {
  DB: 'db',
}
