import actions from './actions'

const initialState = {
  loading: false,
  payMethod: [],
  loadingCountry: false,
  country: [],
  loadingDepto: false,
  deptos: [],
  loadingMunicipality: false,
  municipalities: [],
  loadingAddress: false,
  address: [],
}

export default function useReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    // case actions.LOAD_ASYNC: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   }
    // }
    case actions.SET_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case actions.LOAD_COUNTRY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case actions.LOAD_DEPTO: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case actions.LOAD_MUNICIPALITY: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case actions.LOAD_ADDRESS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}
