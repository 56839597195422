import React from 'react'
import { Spin } from 'antd'

const Loader = ({ spinning = true }) => (
  <div className="container-view full-centering" style={{ position: 'absolute', width: '100%' }}>
    <Spin spinning={spinning} />
  </div>
)
export const SimpleLoader = ({ spinning = true, style = {} }) => {
  const currentStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  }
  const usedStyles = { ...currentStyles, ...style }
  return <Spin spinning={spinning} style={usedStyles} />
}

export default Loader
