import { all, takeEvery, put as putAction, call } from 'redux-saga/effects'
import { safeSagaRequest } from 'helpers/safeSaga'
import {
  historialTransactionsService,
  activityScheduleService,
  transactionsStatusService,
  appCodeService,
  typeRejectService,
} from '../../../services/resume'
import actions, {
  setHistorialTransactions,
  setActivitySchedule,
  setTransactionsStatus,
  setAppCode,
  setTypeReject,
} from './actions'

export function* HISTORIAL_TRANSACTIONS({ payload }) {
  yield putAction(setHistorialTransactions({ loadHistorialTransactions: true }))
  // const params = getParams(payload)
  const labels = []
  const datosOk = {
    label: 'Aprobado',
    backgroundColor: 'rgba(60,179,113,0.8)',
    borderColor: 'rgba(60,179,113,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(50,205,50,0.4)',
    hoverBorderColor: 'rgba(50,205,50,1)',
    data: [],
  }
  const datosError = {
    label: 'Rechazado',
    backgroundColor: 'rgba(255,0,0,0.8)',
    borderColor: 'rgba(255,0,0,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(240,128,128,0.4)',
    hoverBorderColor: 'rgba(240,128,128,1)',
    data: [],
  }
  const datosPending = {
    label: 'Pendiente',
    backgroundColor: 'rgba(30,144,255,0.8)',
    borderColor: 'rgba(30,144,255,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(30,144,255,0.4)',
    hoverBorderColor: 'rgba(30,144,255,1)',
    data: [],
  }
  try {
    const success = yield call(historialTransactionsService, payload)
    if (success && success.data && success.data.values) {
      const jsonAux = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of success.data.values) {
        if (!labels.includes(entry.date)) {
          labels.push(entry.date)
        }
        if (!jsonAux[entry.date]) {
          jsonAux[entry.date] = {}
        }
        const statusS = `status-${entry.status}`
        jsonAux[entry.date][statusS] = entry
      }
      const labelInvertida = labels.reverse()
      // eslint-disable-next-line no-restricted-syntax
      for (const label of labelInvertida) {
        // count pendng
        if (jsonAux[label]['status-pending']) {
          datosPending.data.push(jsonAux[label]['status-pending'].count)
        } else {
          datosPending.data.push(0)
        }

        // count ok
        if (jsonAux[label]['status-success'] || jsonAux[label]['status-approved']) {
          const successCount = jsonAux[label]['status-success']
            ? jsonAux[label]['status-success'].count
            : 0
          const approvedCount = jsonAux[label]['status-approved']
            ? jsonAux[label]['status-approved'].count
            : 0
          datosOk.data.push(successCount + approvedCount)
        } else {
          datosOk.data.push(0)
        }
        // count error
        if (jsonAux[label]['status-error'] || jsonAux[label]['status-failure']) {
          const errorCount = jsonAux[label]['status-error']
            ? jsonAux[label]['status-error'].count
            : 0
          const failureCount = jsonAux[label]['status-failure']
            ? jsonAux[label]['status-failure'].count
            : 0
          datosError.data.push(errorCount + failureCount)
        } else {
          datosError.data.push(0)
        }
        //console.log(jsonAux[label])
      }
    }
    //console.log(datosOk, datosError, datosPending)
  } catch (error) {
    console.log(error)
  }
  yield putAction(setHistorialTransactions({ loadHistorialTransactions: false }))
  yield putAction(
    setHistorialTransactions({
      historialTransactions: { labels, datasets: { datosOk, datosError, datosPending } },
    }),
  )
}

export function* ACTIVITY_SCHEDULE({ payload }) {
  yield putAction(setActivitySchedule({ loadActivitySchedule: true }))
  const labels = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ]
  const datos = {
    label: 'Transacciones',
    backgroundColor: 'rgba(135,206,235,0.8)',
    borderColor: 'rgba(135,206,235,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(135,206,250,0.4)',
    hoverBorderColor: 'rgba(135,206,250,1)',
    data: [],
  }
  try {
    const success = yield call(activityScheduleService, payload)
    if (success && success.data && success.data.values) {
      // eslint-disable-next-line no-restricted-syntax
      for (const label of labels) {
        let valor = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const entry of success.data.values) {
          if (`${label}:00` === entry.date) {
            valor = entry.count
          }
        }
        datos.data.push(valor)
      }
    }
  } catch (error) {
    console.log(error)
  }
  yield putAction(setActivitySchedule({ loadActivitySchedule: false }))
  yield putAction(setActivitySchedule({ activitySchedule: { labels, datasets: [datos] } }))
}

export function* TRANSACTIONS_STATUS({ payload }) {
  yield putAction(setTransactionsStatus({ loadTransactionsStatus: true }))

  const datosOk = {
    label: 'Aprobadas',
    backgroundColor: 'rgba(60,179,113,0.8)',
    borderColor: 'rgba(60,179,113,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(50,205,50,0.4)',
    hoverBorderColor: 'rgba(50,205,50,1)',
    data: [],
  }
  const datosError = {
    label: 'Rechazadas',
    backgroundColor: 'rgba(255,0,0,0.8)',
    borderColor: 'rgba(255,0,0,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(240,128,128,0.4)',
    hoverBorderColor: 'rgba(240,128,128,1)',
    data: [],
  }
  const datosPending = {
    label: 'Pendientes',
    backgroundColor: 'rgba(30,144,255,0.8)',
    borderColor: 'rgba(30,144,255,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(30,144,255,0.4)',
    hoverBorderColor: 'rgba(30,144,255,1)',
    data: [],
  }
  const labels = []

  try {
    const success = yield call(transactionsStatusService, payload)
    const jsonAux = {}
    if (success && success.data && success.data.values) {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of success.data.values) {
        if (!labels.includes(entry.currency)) {
          labels.push(entry.currency)
        }
        if (!jsonAux[entry.currency]) {
          jsonAux[entry.currency] = {}
        }
        const statusS = `status-${entry.status}`
        jsonAux[entry.currency][statusS] = entry
      }
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const money of labels) {
      if (jsonAux[money]['status-pending']) {
        datosPending.data.push(Math.round(jsonAux[money]['status-pending'].sum * 100) / 100)
      } else {
        datosPending.data.push(0)
      }

      // count ok
      if (jsonAux[money]['status-success'] || jsonAux[money]['status-approved']) {
        const successCount = jsonAux[money]['status-success']
          ? jsonAux[money]['status-success'].sum
          : 0
        const approvedCount = jsonAux[money]['status-approved']
          ? jsonAux[money]['status-approved'].sum
          : 0
        datosOk.data.push(Math.round((successCount + approvedCount) * 100) / 100)
      } else {
        datosOk.data.push(0)
      }
      // count error
      if (jsonAux[money]['status-error'] || jsonAux[money]['status-failure']) {
        const errorCount = jsonAux[money]['status-error'] ? jsonAux[money]['status-error'].sum : 0
        const failureCount = jsonAux[money]['status-failure']
          ? jsonAux[money]['status-failure'].sum
          : 0
        datosError.data.push(Math.round((errorCount + failureCount) * 100) / 100)
      } else {
        datosError.data.push(0)
      }
    }
  } catch (error) {
    console.log(error)
  }
  yield putAction(setTransactionsStatus({ loadTransactionsStatus: false }))
  yield putAction(
    setTransactionsStatus({
      transactionsStatus: { labels, datasets: { datosOk, datosError, datosPending } },
    }),
  )
}

export function* GRAPHIC_APP_CODE({ payload }) {
  yield putAction(setAppCode({ loadAppCode: true }))
  //const params = getParams(payload)
  const labels = []
  const datosOk = {
    label: 'Aprobadas',
    backgroundColor: 'rgba(60,179,113,0.8)',
    borderColor: 'rgba(60,179,113,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(50,205,50,0.4)',
    hoverBorderColor: 'rgba(50,205,50,1)',
    data: [],
  }
  const datosError = {
    label: 'Rechazadas',
    backgroundColor: 'rgba(255,0,0,0.8)',
    borderColor: 'rgba(255,0,0,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(240,128,128,0.4)',
    hoverBorderColor: 'rgba(240,128,128,1)',
    data: [],
  }

  try {
    const success = yield call(appCodeService, payload)
    if (success && success.data && success.data.values) {
      const resultado = success.data.values
      const llaves = Object.keys(success.data.values)
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of llaves) {
        labels.push(entry)
        let ok = 0
        let reject = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const datos of resultado[entry]) {
          if (datos.status === 'success') {
            ok = datos.count
          }
          if (datos.status === 'error') {
            reject = datos.count
          }
        }
        datosOk.data.push((ok * 100) / (ok + reject))
        datosError.data.push((reject * 100) / (ok + reject))
      }
    }
  } catch (error) {
    console.log(error)
  }
  yield putAction(setAppCode({ loadAppCode: false }))
  yield putAction(setAppCode({ appCode: { labels, datasets: { datosOk, datosError } } }))
}

export function* TYPE_REJECT({ payload }) {
  yield putAction(setTypeReject({ loadTypeReject: true }))

  const labels = []
  const data = []
  const backgroundColor = []
  const hoverBackgroundColor = []

  try {
    const success = yield call(typeRejectService, payload)
    if (success && success.data && success.data.values) {
      const info = success.data.values
      info.sort((a, b) => {
        return b.count - a.count
      })
      let filtrados = info
      if (info.length > 10) {
        filtrados = []
        let indice = 0
        let otros = 0
        info.forEach(current => {
          if (indice < 10) {
            filtrados.push(current)
            // eslint-disable-next-line no-plusplus
            indice++
          } else {
            otros += current.count
          }
        })
        // TODO mover esta etiqueta al translate
        filtrados.push({ count: otros, status: 'Otros' })
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of filtrados) {
        labels.push(getLabelReject(entry.status))
        data.push(entry.count)
        const r = Math.round(Math.random() * 255)
        const g = Math.round(Math.random() * 255)
        const b = Math.round(Math.random() * 255)
        backgroundColor.push(`rgba(${r},${g},${b}, 1)`)
        hoverBackgroundColor.push(`rgba(${r},${g},${b},0.5)`)
      }
    }
  } catch (error) {
    console.log(error)
  }
  yield putAction(setTypeReject({ loadTypeReject: false }))
  yield putAction(
    setTypeReject({
      typeReject: { labels, datasets: { data, backgroundColor, hoverBackgroundColor } },
    }),
  )
}

const getLabelReject = (status, catalogo) => {
  if (catalogo) {
    const lang = 'es'
    // eslint-disable-next-line no-restricted-syntax
    for (const entry of catalogo) {
      if (entry.type === status) {
        return entry[lang]
      }
    }
  }
  return status
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.HISTORIAL_TRANSACTIONS,
      safeSagaRequest(HISTORIAL_TRANSACTIONS, actions.HISTORIAL_TRANSACTIONS),
    ),
    takeEvery(
      actions.ACTIVITY_SCHEDULE,
      safeSagaRequest(ACTIVITY_SCHEDULE, actions.ACTIVITY_SCHEDULE),
    ),
    takeEvery(
      actions.TRANSACTIONS_STATUS,
      safeSagaRequest(TRANSACTIONS_STATUS, actions.TRANSACTIONS_STATUS),
    ),
    takeEvery(actions.APP_CODE, safeSagaRequest(GRAPHIC_APP_CODE, actions.APP_CODE)),
    takeEvery(actions.TYPE_REJECT, safeSagaRequest(TYPE_REJECT, actions.TYPE_REJECT)),
  ])
}
