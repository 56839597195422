import { publicAxios, privateAxios } from 'services'
import endpoints from 'constants/endpoints'

const qs = require('qs')

export const loadPublicCommerceAll = () =>
  publicAxios().request({
    method: 'get',
    url: endpoints.COMMERCE.GET_ALL,
  })

export const loadPublicCommerceCategories = () =>
  publicAxios().request({
    method: 'get',
    url: endpoints.COMMERCE_ATTR.CATEGORIES,
  })

export const loadCommerceAll = urlPass =>
  privateAxios().request({
    method: 'get',
    url: urlPass,
  })

export function createCommerce(datos) {
  return privateAxios().request({
    method: 'post',
    url: endpoints.COMMERCE_ATTR.CREATE,
    data: datos,
  })
}

export const findCommerce = parametros =>
  privateAxios().request({
    method: 'get',
    url: endpoints.COMMERCE.FIND_PRIVATE_ALL,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

// Eliminar comercio creado
export const deleteCommerce = id =>
  privateAxios().request({
    method: 'delete',
    url: `${endpoints.COMMERCE.DELETE_COMMERCE}/${id}`,
  })

// Obtiene lista de comercios eliminados
export const listCommercesDeletedService = commerces =>
  privateAxios().request({
    method: 'get',
    url: endpoints.COMMERCE.LIST_COMMERCES_DELETED,
    params: commerces,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

export const getCommerce = id =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.COMMERCE.GET}/${id}`,
  })

export const updateCredentials = datos =>
  privateAxios().request({
    method: 'put',
    url: endpoints.COMMERCE.UPDATE_CREDENTIALS,
    data: datos,
  })
export const updateCommerce = datos =>
  privateAxios().request({
    method: 'put',
    url: endpoints.COMMERCE.UPDATE_COMMERCE,
    data: datos,
  })

export const loadPrivateCommerces = () =>
  privateAxios().request({
    method: 'get',
    url: endpoints.COMMERCE.GET_PRIVATE_ALL,
  })

export const loadPrivateCommercesById = parametros =>
  privateAxios().request({
    method: 'get',
    url: endpoints.COMMERCE.GET_PRIVATE_ALL,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

export const updateCommerceCategory = datos =>
  privateAxios().request({
    method: 'put',
    url: endpoints.COMMERCE.UPDATE_CATEGORY,
    data: datos,
  })

// Bitácora
export const tableLogsService = datos =>
  privateAxios().request({
    method: 'get',
    url: endpoints.COMMERCE.TABLE_LOGS,
    params: datos,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
export const newPayMethod = datos =>
  privateAxios().request({
    method: 'put',
    url: `${endpoints.COMMERCE.ADD_NEW_PAYMENTH}?id=${datos.commerce_id}`,
    data: datos,
  })

export const loadListCountries = () =>
  privateAxios().request({
    method: 'get',
    url: endpoints.COMMERCE.COUNTRIES_LIST,
  })
export function createListExcluded(datos) {
  return privateAxios().request({
    method: 'post',
    url: `${endpoints.COMMERCE.EXCLUDE_COUNTRIES_LIST}/${datos.id}`,
    data: datos.selectedscountries,
  })
}

export const loadListExcludeCountries = datos =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.COMMERCE.EXCLUDE_COUNTRIES_LIST}/${datos}`,
  })
export const loadListCountriesPublic = id =>
  publicAxios().request({
    method: 'get',
    url: `${endpoints.CREDITCARD.LIST_COUNTRIES_PUBLIC}/${id}`,
  })

export const getFileCommerce = data =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.GENERAL.FILE_DOWNLOAD}${data}`,
    responseType: 'blob',
  })

export default {
  loadPublicCommerceAll,
  loadPublicCommerceCategories,
  loadCommerceAll,
  findCommerce,
  listCommercesDeletedService,
  getCommerce,
  updateCredentials,
  loadPrivateCommerces,
  loadPrivateCommercesById,
  tableLogsService,
  newPayMethod,
  loadListCountries,
  createListExcluded,
  loadListExcludeCountries,
  loadListCountriesPublic,
}
