const actions = {
  LOAD_USER: 'usuarios/LOAD_USER',
  LOAD_USER_ASYNC: 'usuarios/LOAD_USER_ASYNC',
  CHANGE_VALUE: 'usuario/CHANGE_VALUE',
  LOAD_COMMERCES_ASYNC: 'usuario/LOAD_COMMERCES_ASYNC',
  CREATE_USER_ASYNC: 'usuario/CREATE_USER_ASYNC',
  UPDATE_USER_ASYNC: 'usuario/UPDATE_USER_ASYNC',
}

export const ACTION_changeValue = data => ({
  type: actions.CHANGE_VALUE,
  payload: data,
})

export const ACTION_LoadUser = data => ({
  type: actions.LOAD_USER_ASYNC,
  payload: data,
})
export const ACTION_LoadCommerces = data => ({
  type: actions.LOAD_COMMERCES_ASYNC,
  payload: data,
})
export const ACTION_CreateUser = data => ({
  type: actions.CREATE_USER_ASYNC,
  payload: data,
})

export const ACTION_closeSuccessUser = () => ({
  type: actions.CHANGE_VALUE,
  payload: { success: false, onError: false },
})

export const ACTION_UpdateUser = data => ({
  type: actions.UPDATE_USER_ASYNC,
  payload: data,
})

export default actions
