import { all, takeEvery, put as putAction, call } from 'redux-saga/effects'
import {
  getCommerceId,
  cardCommerce,
  getBankCommerceId,
  getTransactionId,
  getExchangeCurrencyV,
  getConsulterCommerce,
} from 'services/commercePublic'
import { loadPersonDocuments, loadPaymentMethods, loadIp } from 'services/general'
import { getFactura, getDataFiduciary } from 'services/ticket'
import { setPayTicketCommerce } from 'models/redux/payTicket/actions'
import actions, {
  setCommercePublicData,
  setCurrencyExchangeData,
  updateCommerceValidationStatus,
} from './actions'

export function* CARD_COMMERCE(data) {
  yield putAction(setCommercePublicData({ loading: true }))
  yield putAction(setCommercePublicData({ commercePublicData: {} }))
  try {
    const success = yield call(getCommerceId, data.payload)
    yield putAction(setCommercePublicData({ commercePublicData: success.data }))
  } catch (error) {
    console.log(error)
    try {
      const successList = yield call(cardCommerce)
      let comercio = {}
      successList.data.forEach(element => {
        if (Number(element.commerce_id) === Number(data.payload)) {
          comercio = element
        }
      })
      yield putAction(setCommercePublicData({ commercePublicData: comercio }))
    } catch (e) {
      console.log(e)
    }
  }
  yield putAction(setCommercePublicData({ loading: false }))

  try {
    yield putAction(setCommercePublicData({ banks: [] }))
    const successBank = yield call(getBankCommerceId, data.payload)
    const banksFilter = []
    if (successBank.data.banks) {
      successBank.data.banks.forEach(element => {
        if (element.code !== '0') {
          banksFilter.push(element)
        }
      })
    }
    yield putAction(setCommercePublicData({ banks: banksFilter }))
  } catch (error) {
    console.log(error)
  }
}

export function* GET_CURRENCY_E_V(data) {
  try {
    const success = yield call(getExchangeCurrencyV, data.payload)
    yield putAction(setCurrencyExchangeData(success.data))
  } catch (error) {
    console.error(error)
  }
}

export function* INIT_OPTIONS_COMMERCE() {
  yield putAction(setCommercePublicData({ docLoading: false }))
  yield putAction(setCommercePublicData({ facturasPending: [] }))
  yield putAction(setCommercePublicData({ selectedInvoices: null }))
  yield putAction(setCommercePublicData({ infoSummary: null }))
  yield putAction(setCommercePublicData({ ip: '' }))
  yield putAction(setPayTicketCommerce({ payTicket: null }))
  yield putAction(setPayTicketCommerce({ responsePSE: '' }))
  yield putAction(setPayTicketCommerce({ responseTC: '' }))
  yield putAction(setPayTicketCommerce({ loading: false }))

  try {
    const successDoc = yield call(loadPersonDocuments)
    yield putAction(setCommercePublicData({ documents: successDoc.data }))
  } catch (error) {
    console.log(error)
  }
  try {
    const successPay = yield call(loadPaymentMethods)
    yield putAction(setCommercePublicData({ paymentMethods: successPay.data }))
  } catch (error) {
    console.log(error)
  }
  try {
    const successIp = yield call(loadIp)
    console.log(successIp)
    yield putAction(setCommercePublicData({ ip: successIp.data.ip }))
  } catch (error) {
    console.log(error)
  }
}

export function* GET_FACTURA_COMMERCE(data) {
  yield putAction(
    setCommercePublicData({
      userData: {
        client_id: data.payload.document_id,
        document_key: data.payload.document_key || null,
      },
    }),
  )

  yield putAction(setCommercePublicData({ docLoading: true }))
  yield putAction(setCommercePublicData({ facturasPending: [] }))
  yield putAction(setCommercePublicData({ selectedInvoices: null }))
  try {
    const success = yield call(getFactura, data.payload)
    yield putAction(setCommercePublicData({ facturasPending: success.data.tickets }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(setCommercePublicData({ docLoading: false }))
}

export function* GET_TRANSACTION_COMMERCE(data) {
  yield putAction(setCommercePublicData({ loading: true }))
  yield putAction(setCommercePublicData({ commercePublicData: {} }))
  yield putAction(setCommercePublicData({ transaction: {} }))
  try {
    const success = yield call(getTransactionId, data.payload)
    const trx = success.data
    yield putAction(setCommercePublicData({ transaction: trx }))
    try {
      const successC = yield call(getCommerceId, trx.commerce_id)
      yield putAction(setCommercePublicData({ commercePublicData: successC.data }))
    } catch (error) {
      console.log(error)
    }
  } catch (error) {
    console.log(error)
  }
  yield putAction(setCommercePublicData({ loading: false }))
}

export function* LOAD_COMMERCE_CACHE({ payload: data }) {
  yield putAction(setCommercePublicData({ loading: true }))
  const cache = localStorage.getItem('comercioConsultado')
  if (cache) {
    const elements = cache.split(',')
    let existe = false
    elements.forEach(comercio => {
      if (data === comercio) {
        existe = true
      }
    })
    if (!existe) {
      elements.push(data)
    }
    localStorage.setItem('comercioConsultado', elements.toString())
  } else {
    localStorage.setItem('comercioConsultado', data)
  }

  yield putAction(setCommercePublicData({ loading: false }))
}

export function* UPDATE_FORM_VALID_STATUS(data) {
  try {
    yield putAction(updateCommerceValidationStatus(data.payload))
  } catch (error) {
    console.error(error)
  }
}

export function* GET_COMMERCE_INTEGRATION(data) {
  yield putAction(setCommercePublicData({ loading: true }))
  yield putAction(setCommercePublicData({ commerceIntegrationData: {} }))
  try {
    const success = yield call(getConsulterCommerce, data.payload)
    yield putAction(setCommercePublicData({ commerceIntegrationData: success.data }))
  } catch (error) {
    console.log(error)
  }
  try {
    const successDoc = yield call(loadPersonDocuments)
    yield putAction(setCommercePublicData({ documents: successDoc.data }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(setCommercePublicData({ loading: false }))
}

export function* GET_COMMERCE_FIDUCIARY(data) {
  yield putAction(
    setCommercePublicData({
      userData: {
        client_id: data.payload.id_ticket,
        client_key: data.payload.key_ticket,
        amount: data.payload.amount,
        commerce_id: data.payload.commerce_id,
        document_key: 31,
      },
    }),
  )

  yield putAction(setCommercePublicData({ docLoading: true }))
  yield putAction(setCommercePublicData({ paymentFiduciary: null }))
  yield putAction(setCommercePublicData({ fiduciariaConsultada: false }))
  yield putAction(setCommercePublicData({ errorFiduciaria: '' }))
  console.log('Consulta fiduciaira')
  try {
    console.log('Consulta fiduciaira')
    const success = yield call(getDataFiduciary, data.payload)
    yield putAction(setCommercePublicData({ paymentFiduciary: success.data }))
  } catch (error) {
    let valor = ''
    if (error && error.response.data && error.response.data.message) {
      valor = error.response.data.message
    }
    yield putAction(setCommercePublicData({ errorFiduciaria: valor }))
  }
  yield putAction(setCommercePublicData({ docLoading: false }))
  yield putAction(setCommercePublicData({ fiduciariaConsultada: true }))
}

export function* SET_CLEAR_CHARGES_DATA(data) {
  if (data.payload === 'fiduciaria') {
    //aqui reiniciamos fiduciaria
    yield putAction(
      setCommercePublicData({
        paymentFiduciary: null,
        fiduciariaConsultada: false,
        userData: {},
        facturasPending: [],
        selectedInvoices: null,
      }),
    )
  } else {
    yield putAction(
      setCommercePublicData({
        userData: {},
        facturasPending: [],
        selectedInvoices: null,
      }),
    )
  }
}

export function* SET_CLEAR_PENDING_INVOICES_DATA(data) {
  try {
    yield putAction(setCommercePublicData({ facturasPending: data.payload }))
  } catch (error) {
    console.log(error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.EXCHANGE_VALUE, GET_CURRENCY_E_V)])
  yield all([takeEvery(actions.COMMERCE_DATA, CARD_COMMERCE)])
  yield all([takeEvery(actions.COMMERCE_OPTIONS_DATA, INIT_OPTIONS_COMMERCE)])
  yield all([takeEvery(actions.COMMERCE_GET_FACTURA, GET_FACTURA_COMMERCE)])
  yield all([takeEvery(actions.COMMERCE_TRANSACTION_DATA, GET_TRANSACTION_COMMERCE)])
  yield all([takeEvery(actions.LOAD_CACHE_COMMERCE, LOAD_COMMERCE_CACHE)])
  yield all([takeEvery(actions.COMMERCE_UPDATE_FORM_VALID_STATUS, UPDATE_FORM_VALID_STATUS)])
  yield all([takeEvery(actions.GET_PAYMENT_FIDUCIARY, GET_COMMERCE_FIDUCIARY)])
  yield all([takeEvery(actions.COMMERCE_INTEGRATION, GET_COMMERCE_INTEGRATION)])
  yield all([takeEvery(actions.CLEAR_CHARGES_DATA, SET_CLEAR_CHARGES_DATA)])
  yield all([takeEvery(actions.CLEAR_PENDING_INVOICES, SET_CLEAR_PENDING_INVOICES_DATA)])
}
