import actions from './actions'

const initialState = {
  reportesLoading: false,
  reportMessage: null,
  reportes: [],
  success: false,
  onError: false,
  tableLoading: false,
  tableEvents: null,
  eventName: [],
  loading: false,
  loadingreportesEvent: false,
  reportesEvent: [],
  okEvent: false,
  errorEvent: false,
  tableEventsFilters: [],
}

export default function reportes(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD: {
      const { payload = [] } = action
      return { ...state, ...payload }
    }
    case actions.LOAD_EXPORT: {
      const { payload = [] } = action
      return { ...state, ...payload }
    }
    case actions.LOAD_TABLE_EVENTS: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.TABLE_EVENTS: {
      const { payload = {} } = action
      return {
        ...state,
        tableEvents: payload,
      }
    }
    case actions.LOAD_NAME_EVENTS: {
      const { payload = {} } = action
      return {
        ...state,
        ...payload,
      }
    }
    case actions.LOAD_EXPORT_EVENTS: {
      const { payload = [] } = action
      return { ...state, ...payload }
    }
    default:
      return state
  }
}
