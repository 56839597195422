const actions = {
  SET_ERROR_MESSAGES: 'retries/SET_ERROR_MESSAGES',
  RETRIES_GET_ERROR_MESSAGES_LIST: 'retries/RETRIES_GET_ERROR_MESSAGES_LIST',
  LOAD: 'retries/LOAD',
  SET_INTEGRATION: 'retries/SET_INTEGRATION',
  TABLE_TRANSACTIONS: 'retries/TABLE_TRANSACTIONS',
  LOAD_COMMERCE_INTEGRATION: 'retries/LOAD_COMMERCE_INTEGRATION',
  SET_TRANSACTIONS: 'retries/SET_TRANSACTIONS',
  RETRY_TRANSACTIONS: 'retries/RETRY_TRANSACTIONS',
  EXPORT_TRANSACTIONS: 'retries/EXPORT_TRANSACTIONS',
}

// GET ERROR MESSAGES
export const ACTION_LoadErrrorMessages = (commerces = []) => ({
  type: actions.RETRIES_GET_ERROR_MESSAGES_LIST,
  payload: commerces,
})

export const retriesGetIntegrationData = data => ({
  type: actions.LOAD_COMMERCE_INTEGRATION,
  payload: { ...data },
})

export const tableRetriesGetTransactions = data => ({
  type: actions.TABLE_TRANSACTIONS,
  payload: { ...data },
})

export const ACTION_LOAD_TRANSACTIONS = (transactionsData = []) => ({
  type: actions.LOAD,
  payload: transactionsData,
})

export const ACTION_SET_INTEGRATION_DATA = integrationData => ({
  type: actions.SET_INTEGRATION,
  payload: integrationData,
})

export const setListOfErrorMessages = (data = []) => ({
  type: actions.SET_ERROR_MESSAGES,
  payload: data,
})

export const retriesSetTransactions = (data = []) => ({
  type: actions.SET_TRANSACTIONS,
  payload: data,
})

export const retriesRetryTransactions = (data = []) => ({
  type: actions.RETRY_TRANSACTIONS,
  payload: data,
})

export const retriesExportTransactions = (data = []) => ({
  type: actions.EXPORT_TRANSACTIONS,
  payload: data,
})

export default actions
