import { publicAxios } from 'services'
import endpoints from 'constants/endpoints'

export const typeMethod = () =>
  publicAxios().request({
    method: 'get',
    url: endpoints.METHOD.PAY,
  })

/** Campos de dirección para tarjeta de crédito **/
export const countriesService = () =>
  publicAxios().request({
    method: 'get',
    url: endpoints.CREDITCARD.COUNTRIES,
  })

export const deptosService = () =>
  publicAxios().request({
    method: 'get',
    url: endpoints.CREDITCARD.DEPTOS,
  })

export const municipalityService = code =>
  publicAxios().request({
    method: 'get',
    url: `${endpoints.CREDITCARD.MUNICIPALITY}/${code}`,
  })

export const addressListService = () =>
  publicAxios().request({
    method: 'get',
    url: endpoints.CREDITCARD.ADDRESS_LIST,
  })

export default {
  typeMethod,
  countriesService,
  deptosService,
  municipalityService,
  addressListService,
}
