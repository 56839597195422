import { publicAxios, privateAxios } from 'services'
import endpoints from 'constants/endpoints'

const qs = require('qs')

export const getCategories = () =>
  publicAxios().request({
    method: 'get',
    url: endpoints.COMMERCE_ATTR.CATEGORIES,
  })

export const createCategory = parameters =>
  privateAxios().request({
    method: 'post',
    url: endpoints.CATEGORIES.CATEGORY,
    data: parameters,
  })

export const updateCategory = data =>
  privateAxios().request({
    method: 'put',
    url: endpoints.CATEGORIES.CATEGORY,
    data,
  })

export const deleteCategory = id =>
  privateAxios().request({
    method: 'delete',
    url: `${endpoints.CATEGORIES.CATEGORY}/${id}`,
  })

export const updateCategoryOrder = datos =>
  privateAxios().request({
    method: 'put',
    url: endpoints.CATEGORIES.UPDATE_ORDER,
    data: datos,
  })

export function updateCommerceCategory(parameters) {
  return privateAxios().request({
    method: 'post',
    url: endpoints.COMMERCE.UPDATE_CATEGORY,
    params: parameters,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export default {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  updateCategoryOrder,
  updateCommerceCategory,
}
