import actions from './actions'

/**
 * Referirse a src/assets/json/commerce.json para revisar estructura de array
 */

const initialState = {
  public: [],
  private: [],
  publicCategorized: [],
  categories: [],
  commercesDeleted: [],
  listLogs: [],
  listCountries: [],
  excludeCountries: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_ASYNC_PRIVATE_COMMERCES: {
      const { payload = [] } = action
      return {
        ...state,
        private: payload,
      }
    }
    case actions.LOAD: {
      const { payload = [] } = action
      return {
        ...state,
        public: payload,
      }
    }
    case actions.LOAD_CATEGORY: {
      const { payload = [] } = action
      return {
        ...state,
        categories: payload,
      }
    }
    case actions.LOAD_CATEGORIZED_COMMERCE: {
      const { payload = [] } = action
      return {
        ...state,
        publicCategorized: payload,
      }
    }
    case actions.LOAD_COMMERCES_DELETED: {
      const { payload = [] } = action
      //console.log(payload)
      return {
        ...state,
        commercesDeleted: payload,
      }
    }
    case actions.LOAD_LIST_TABLE_LOGS: {
      const { payload = [] } = action
      //console.log(payload)
      return {
        ...state,
        listLogs: payload,
      }
    }
    case actions.LOAD_LIST_COUNTRIES: {
      const { payload = [] } = action
      return {
        ...state,
        listCountries: payload,
      }
    }
    case actions.UPDATE_LIST_EXCLUDE_COUNTRIES: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.LOAD_LIST_EXCLUDE_COUNTRIES: {
      const { payload = [] } = action
      return {
        ...state,
        excludeCountries: payload,
      }
    }
    default:
      return state
  }
}
