const actions = {
  LOAD: 'reportes/LOAD',
  LOAD_ASYNC: 'reportes/LOAD_ASYNC',
  LOAD_EXPORT: 'reportes/LOAD_EXPORT',
  EXPORT_REPORTS: 'reportes/EXPORT_REPORTS',
  LOAD_TABLE_EVENTS: 'reportes/LOAD_TABLE_EVENTS',
  TABLE_EVENTS: 'reportes/TABLE_EVENTS',
  LOAD_NAME_EVENTS: 'reportes/LOAD_NAME_EVENTS',
  SET_NAME_EVENTS: 'reportes/SET_NAME_EVENTS',
  LOAD_EXPORT_EVENTS: 'reportes/LOAD_EXPORT_EVENTS',
  EXPORT_EVENTS: 'reportes/EXPORT_EVENTS',
}

// Tabla de todos los reportes
export const ACTION_LoadAllReportes = () => ({
  type: actions.LOAD_ASYNC,
})

// Exportar reportes
export const setExportReports = (reportData = []) => ({
  type: actions.LOAD_EXPORT,
  payload: reportData,
})

export const getExportReports = data => ({
  type: actions.EXPORT_REPORTS,
  payload: { ...data },
})

export const ACTION_closeSuccessReport = () => ({
  type: actions.LOAD,
  payload: { success: false, onError: false },
})

export const ACTION_LOAD_TABLE_EVENTS = (eventsData = []) => ({
  type: actions.LOAD_TABLE_EVENTS,
  payload: eventsData,
})

export const tableGetEvents = data => ({
  type: actions.TABLE_EVENTS,
  payload: { ...data },
})

export const loadEventName = data => ({
  type: actions.LOAD_NAME_EVENTS,
  payload: data,
})

export const setEventName = data => ({
  type: actions.SET_NAME_EVENTS,
  payload: data,
})

export const loadReportEvent = (reportDataEvent = []) => ({
  type: actions.LOAD_EXPORT_EVENTS,
  payload: reportDataEvent,
})

export const getExportEvent = data => ({
  type: actions.EXPORT_EVENTS,
  payload: { ...data },
})
export const ACTION_closeSuccessReportEvent = () => ({
  type: actions.LOAD_EXPORT_EVENTS,
  payload: { okEvent: false, errorEvent: false },
})

export default actions
