import { ENCRYPT_KEY_REQUEST } from 'constants/base'

const aesjs = require('aes-js')

const eliminarDiacriticos = texto => {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]

//Encrypted request
export function encryptedRequest(data) {
  const key = aesjs.utils.utf8.toBytes(ENCRYPT_KEY_REQUEST)
  // The initialization vector (must be 16 bytes)
  // Json to string en multiplos de 16
  let text = JSON.stringify(data)
  text = eliminarDiacriticos(text)
  if (text.length % 16 !== 0) {
    const falta = 16 - (text.length % 16)
    text = text.padEnd(text.length + falta)
  }
  // data to bytes
  const textBytes = aesjs.utils.utf8.toBytes(text)
  const segmentSize = 16
  //Encypted data
  /* eslint-disable-next-line new-cap */
  const aesCfb = new aesjs.ModeOfOperation.cfb(key, iv, segmentSize)
  const encryptedBytes = aesCfb.encrypt(textBytes)
  // Encrypted data to base 64 formata (iv + dataEncrypted)
  const b64encoded = bufferToBase64(new Uint8Array([...Uint8Array.from(iv), ...encryptedBytes]))
  return b64encoded
}

function bufferToBase64(buf) {
  /* eslint-disable-next-line func-names */
  const binstr = Array.prototype.map
    .call(buf, ch => {
      return String.fromCharCode(ch)
    })
    .join('')
  return btoa(binstr)
}

export function decryptedRequest(data) {
  const key = aesjs.utils.utf8.toBytes(ENCRYPT_KEY_REQUEST)

  /* eslint-disable-next-line new-cap */
  const aesCfb = new aesjs.ModeOfOperation.cfb(key, iv, 16)
  //console.log(data)
  const rawtext = atob(data)
  const dataB = []
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < rawtext.length; i++) {
    dataB.push(rawtext.charCodeAt(i))
  }
  const decryptedBytes = aesCfb.decrypt(dataB)
  // Convert our bytes back into text
  let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes)
  //console.log("decryptedText", decryptedText)
  const position = decryptedText.indexOf('d:')
  decryptedText = decryptedText.substring(position + 2)
  decryptedText = `${decryptedText}`
  //console.log("decryptedText", decryptedText)
  const jsonResponse = JSON.parse(decryptedText)
  //console.log("jsonresponse", jsonResponse)
  return jsonResponse
}

export function decryptedData(data) {
  try {
    const key = aesjs.utils.utf8.toBytes(ENCRYPT_KEY_REQUEST)

    /* eslint-disable-next-line new-cap */
    const aesCfb = new aesjs.ModeOfOperation.cfb(key, iv, 16)
    //console.log(data)
    const rawtext = atob(data)
    const dataB = []
    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < rawtext.length; i++) {
      dataB.push(rawtext.charCodeAt(i))
    }
    const decryptedBytes = aesCfb.decrypt(dataB)
    // Convert our bytes back into text
    let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes)
    //console.log("decryptedText", decryptedText)
    const position = decryptedText.indexOf('d:')
    decryptedText = decryptedText.substring(position + 2)
    //decryptedText = `${decryptedText}`
    //console.log("decryptedText", decryptedText)
    return decryptedText
  } catch (error) {
    console.log(error)
    return '0'
  }
}

export default {
  encryptedRequest,
  decryptedRequest,
}
