import { publicAxios } from 'services'
import endpoints from 'constants/endpoints'

export const cardCommerce = () =>
  publicAxios().request({
    method: 'get',
    url: endpoints.PUBLIC.COMMERCE,
  })

export const getExchangeCurrencyV = currency =>
  publicAxios().request({
    method: 'get',
    url: `${endpoints.PUBLIC.EXCHANGE_CURRENCY}?currency=${currency}`,
  })

export const getCommerceId = idCommerce =>
  publicAxios().request({
    method: 'get',
    url: `${endpoints.PUBLIC.COMMERCE}/${idCommerce}`,
  })

export const getBankCommerceId = idCommerce =>
  publicAxios().request({
    method: 'get',
    url: `${endpoints.PUBLIC.BANKS}/${idCommerce}/banks`,
  })

export const getTransactionId = idCommerce =>
  publicAxios().request({
    method: 'get',
    url: `${endpoints.PUBLIC.TRANSACTION}${idCommerce.id}?captcha=${idCommerce.token}`,
  })

export const getTransactionIdTicket = data =>
  publicAxios().request({
    method: 'get',
    url: `${endpoints.PUBLIC.TICKET}${data.id}?captcha=${data.token}`,
    responseType: 'blob',
  })

export const getConsulterCommerce = data =>
  publicAxios().request({
    method: 'get',
    url: `${endpoints.PUBLIC.CONSULTER_COMMERCE}${data}`,
  })

export default {
  cardCommerce,
  getTransactionIdTicket,
  getConsulterCommerce,
}
