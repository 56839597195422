import { privateAxios } from 'services'
import endpoints from 'constants/endpoints'

const qs = require('qs')

export const loadRetryErrorMessage = parametros =>
  privateAxios().request({
    method: 'get',
    url: endpoints.RETRIES.GET_ERROR_MESSAGES,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

export const tableTransactions = parametros =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.RETRIES.GET_PENDING_TRANSACTION}`,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export function markTransactions(parameters) {
  return privateAxios().request({
    method: 'post',
    url: endpoints.RETRIES.MARK_TRANSACTIONS,
    params: parameters,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}
export function retryTransactions(parameters) {
  return privateAxios().request({
    method: 'post',
    url: endpoints.RETRIES.RETRY_TRANSACTIONS,
    params: parameters,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const reportTransactions = parametros =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.RETRIES.RETRIES_REPORTS}`,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export default {
  loadRetryErrorMessage,
  tableTransactions,
  markTransactions,
  retryTransactions,
  reportTransactions,
}
