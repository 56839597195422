const AUTH = {
  LOGIN: `/v1/admin/login`,
  FORGOT: `/v1/admin/password/reset/request`,
  CHANGE_PASSWORD: `/v1/admin/password/reset`,
}

const USER = {
  CREATE: `/v1/admin/user`,
  UPDATE: `/v1/admin/user`,
  GET: `/v1/admin/user/`,
  GET_ALL: `/v1/admin/user`,
  DELETE_USER: `/v1/admin/user`,
  TOKEN_UPDATE: `/v1/admin/refresh-token`,
  PERFIL_CREATE: '/v1/admin/profile',
  PERFIL_UPDATE: '/v1/admin/profile',
}

const USER_ATTR = {
  DOCUMENT_TYPES: `/v1/catalog/document-person`,
}

const COMMERCE = {
  GET_ALL: `/v1/public/commerce`,
  GET_PRIVATE_ALL: `/v1/admin/commerce`,
  FIND_PRIVATE_ALL: `/v1/admin/commerce-list`,
  GET: `/v1/admin/commerce`,
  UPDATE_CREDENTIALS: `/v1/admin/commerce/credentials`,
  UPDATE_COMMERCE: `/v1/admin/commerce`,
  UPDATE_CATEGORY: `/v1/admin/commerce/category`,
  DELETE_COMMERCE: `/v1/admin/commerce`,
  LIST_COMMERCES_DELETED: `/v1/admin/commerce-delete`,
  TABLE_LOGS: `/v1/admin/bitacora`,
  ADD_NEW_PAYMENTH: `/v1/admin/commerce/new-payment-method/`,
  COUNTRIES_LIST: `/v1/admin/countries-list`,
  EXCLUDE_COUNTRIES_LIST: `/v1/admin/countries-excluded/commerce`,
}

const COMMERCE_ATTR = {
  CATEGORIES: `/v1/catalog/category`,
  CREATE: `/v1/admin/commerce`,
}

const ROLE = {
  CREATE_ROLE: `/v1/admin/role`,
  UPDATE_ROLE: `/v1/admin/role`,
  GET: `/v1/admin/role/{id}`,
  GET_ALL: `/v1/admin/role`,
}

const TICKET = {
  CREATE: `/v1/admin/ticket`,
  IMPORT: `/v1/admin/ticket/importer`,
  GET: `/v1/ticket/{id}`,
  GET_ALL: `/v1/admin/tickets`,
  GET_BY_PERSON: `/v1/tickets/person/{document_type}/{document_number}`,
  GET_BY_COMMERCE: `/v1/tickets/commerce/{id}`,
  GET_BY_COMMERCE_BY_PERSON: `/v1/tickets/commerce/`,
}

const PAY = {
  TICKET: `/v1/transaction/pay`,
  PAY: `/v1/transaction/pay`,
}

const METHOD = {
  PAY: `/v1/catalog/payment-method`,
  PERSON_DOC: `/v1/catalog/document-person`,
  COMMERCE_DOC: `/v1/catalog/document-commerce`,
  TYPE_TICKETS: `/v1/catalog/ticket-type`,
  PAYMENT_CYCLE: `/v1/catalog/payment-cycle`,
  TYPE_ACCOUNT: `/v1/catalog/account-type`,
  TYPE_SERVICE: `/v1/catalog/service-type`,
  STATUS_COMMERCE: `/v1/catalog/status`,
  ROLES: `/v1/admin/role`,
  TYPEBD: `/v1/catalog/type-bd`,
  TOPE_NATURAL: `/v1/catalog/type-tope-natural`,
  TOPE_JURIDICO: `/v1/catalog/type-tope-juridio`,
  CURRENCY_ALLOWED: `/v1/catalog/currency-allowed`,
  SUB_TYPE_TICKETS: `/v1/catalog/sub-type`,

}

const GENERAL = {
  CITIES: `/v1/city`,
  FILE: `/v1/admin/upload/file`,
  FILE_IMAGE: `/v1/admin/upload/image`,
  FILE_DOWNLOAD: `/v1/admin/download/file/`,
}

const PUBLIC = {
  COMMERCE: `/v1/public/commerce`,
  BANKS: `/v1/commerce`,
  TRANSACTION: `/v1/transaction/`,
  TICKET: `/v1/transaction-ticket/`,
  EXCHANGE_CURRENCY: `/v1/admin/trm`,
  CONSULTER_COMMERCE: `/v1/public/commerce-integration/`,
}

const RESUME = {
  HISTORIAL_TRANSACTIONS: `/v1/admin/transactions/analitycs/status_time`,
  ACTIVITY_SCHEDULE: `/v1/admin/transactions/analitycs/activity`,
  TRANSACTIONS_STATUS: `/v1/admin/transactions/analitycs/status`,
  APP_CODE: `/v1/admin/transactions/analitycs/status_app_code`,
  TYPE_REJECT: `/v1/admin/transactions/analitycs/status_reject`,
}

const TRANSACTIONS = {
  TABLE_TRANSACTIONS: `/v1/admin/transactions`,
}

const REPORTS = {
  INIT_REPORTS: '/v1/admin/transactions-report',
  GET_ALL: `/v1/admin/transactions/report`,
  GET_NAME_EVENTS: `/v1/admin/event-list`,
  GET_EVENTS_COMMERCES: `/v1/admin/event-commerce`,
  EXPORT_EVENTS: `/v1/admin/event/report`,
  CLOSE_EVENTS: `/v1/admin/event/close`,
  EXPORT_EVENTS_COMMERCE: `/v1/admin/event/report-commerce`,
}

const CATEGORIES = {
  CATEGORY: '/v1/admin/category',
  UPDATE_ORDER: `/v1/admin/category/order`,
}

const CREDITCARD = {
  COUNTRIES: '/v1/tc/country',
  DEPTOS: '/v1/tc/state',
  MUNICIPALITY: '/v1/tc-city',
  ADDRESS_LIST: '/v1/tc/dir',
  LIST_COUNTRIES_PUBLIC: `/v1/countries/commerce`,
}
const RETRIES = {
  GET_ERROR_MESSAGES: '/v1/admin/transactions/pending/message',
  GET_PENDING_TRANSACTION: '/v1/admin/transactions/pending-bank',
  RETRY_TRANSACTIONS: '/v1/admin/transactions/pending-bank/retry',
  MARK_TRANSACTIONS: '/v1/admin/transactions/pending-bank/mark',
  RETRIES_REPORTS: '/v1/admin/transactions-report/pending-bank',
}

export default {
  AUTH,
  USER,
  ROLE,
  TICKET,
  PAY,
  METHOD,
  USER_ATTR,
  COMMERCE,
  COMMERCE_ATTR,
  GENERAL,
  PUBLIC,
  RESUME,
  TRANSACTIONS,
  REPORTS,
  CATEGORIES,
  CREDITCARD,
  RETRIES,
}
