const actions = {
  LOAD_HISTORIAL_TRANSACTIONS: 'resume/LOAD_HISTORIAL_TRANSACTIONS',
  HISTORIAL_TRANSACTIONS: 'resume/HISTORIAL_TRANSACTIONS',
  LOAD_ACTIVITY_SCHEDULE: 'resume/LOAD_ACTIVITY_SCHEDULE',
  ACTIVITY_SCHEDULE: 'resume/ACTIVITY_SCHEDULE',
  LOAD_TRANSACTIONS_STATUS: 'resume/LOAD_TRANSACTIONS_STATUS',
  TRANSACTIONS_STATUS: 'resume/TRANSACTIONS_STATUS',
  LOAD_APP_CODE: 'resume/LOAD_APP_CODE',
  APP_CODE: 'resume/APP_CODE',
  LOAD_TYPE_REJECT: 'resume/LOAD_TYPE_REJECT',
  TYPE_REJECT: 'resume/TYPE_REJECT',
}

// Grafica "historial de transacciones" (load y datos)
export const setHistorialTransactions = (historialTransactionsData = []) => ({
  type: actions.LOAD_HISTORIAL_TRANSACTIONS,
  payload: historialTransactionsData,
})

export const getHistorialTransactions = data => ({
  type: actions.HISTORIAL_TRANSACTIONS,
  payload: { ...data },
})

// Grafica "Horario de actividad" (load y datos)
export const setActivitySchedule = (activityScheduleData = []) => ({
  type: actions.LOAD_ACTIVITY_SCHEDULE,
  payload: activityScheduleData,
})

export const getActivitySchedule = data => ({
  type: actions.ACTIVITY_SCHEDULE,
  payload: { ...data },
})

// Grafica "Estado de transacciones" (load y datos)
export const setTransactionsStatus = (transactionsStatusData = []) => ({
  type: actions.LOAD_TRANSACTIONS_STATUS,
  payload: transactionsStatusData,
})
export const getTransactionsStatus = data => ({
  type: actions.TRANSACTIONS_STATUS,
  payload: { ...data },
})

// Grafica "App code" (load y datos)
export const setAppCode = (carriersData = []) => ({
  type: actions.LOAD_APP_CODE,
  payload: carriersData,
})

export const getAppCode = data => ({
  type: actions.APP_CODE,
  payload: { ...data },
})

// Grafica "Tipo de rechazo" (load y datos)
export const setTypeReject = (typeRejectData = []) => ({
  type: actions.LOAD_TYPE_REJECT,
  payload: typeRejectData,
})
export const getTypeReject = data => ({
  type: actions.TYPE_REJECT,
  payload: { ...data },
})

export default actions
