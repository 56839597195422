import { all, takeEvery, put as putAction, call } from 'redux-saga/effects'
import {
  typeMethod,
  countriesService,
  deptosService,
  municipalityService,
  addressListService,
} from 'services/payMethod'
import actions, {
  setPayMethodData,
  loadCountryTC,
  loadDeptoTC,
  loadMunicipalityTC,
  loadAddressTC,
} from './actions'

export function* PAY_METHOD() {
  yield putAction(setPayMethodData({ loading: true }))
  yield putAction(setPayMethodData({ payMethod: [] }))
  try {
    const success = yield call(typeMethod)
    yield putAction(setPayMethodData({ payMethod: success.data }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(setPayMethodData({ loading: false }))
}

export function* COUNTRIES_LIST() {
  yield putAction(loadCountryTC({ loadingCountry: true }))
  yield putAction(loadCountryTC({ country: [] }))
  try {
    const success = yield call(countriesService)
    yield putAction(loadCountryTC({ country: success.data }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(loadCountryTC({ loadingCountry: false }))
}

export function* DEPTOS_LIST() {
  yield putAction(loadDeptoTC({ loadingDepto: true }))
  yield putAction(loadDeptoTC({ deptos: [] }))
  try {
    const success = yield call(deptosService)
    yield putAction(loadDeptoTC({ deptos: success.data }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(loadDeptoTC({ loadingDepto: false }))
}

export function* MUNICIPALITY_LIST({ payload }) {
  //console.log(payload)
  yield putAction(loadMunicipalityTC({ loadingMunicipality: true }))
  yield putAction(loadMunicipalityTC({ municipalities: [] }))
  try {
    const success = yield call(municipalityService, payload)
    yield putAction(loadMunicipalityTC({ municipalities: success.data }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(loadMunicipalityTC({ loadingMunicipality: false }))
}

export function* ADDRESS_LIST() {
  yield putAction(loadAddressTC({ loadingAddress: true }))
  yield putAction(loadAddressTC({ address: [] }))
  try {
    const success = yield call(addressListService)
    yield putAction(loadAddressTC({ address: success.data }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(loadAddressTC({ loadingAddress: false }))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ASYNC, PAY_METHOD),
    takeEvery(actions.SET_COUNTRY, COUNTRIES_LIST),
    takeEvery(actions.SET_DEPTO, DEPTOS_LIST),
    takeEvery(actions.SET_MUNICIPALITY, MUNICIPALITY_LIST),
    takeEvery(actions.SET_ADDRESS, ADDRESS_LIST),
  ])
}
