/* eslint-disable no-nested-ternary */
import { all, takeEvery, put as putAction, call } from 'redux-saga/effects'
import { payTicketData } from 'services/payTicket'
import moment from 'moment'
import store from 'models/store'
import { getInvoicesTotalPay } from 'utils/invoices'
import actions, { setPayTicketCommerce } from './actions'

const cleanInvoices = (bills = [], userData, amountFromForm = false) => {
  return bills.map(bill => ({
    ticket_id: bill.id || 0,
    client_id: bill.client_id || userData?.client_id,
    client_key: bill.client_key || userData?.document_key || null,
    participacion: bill.participacion || userData?.participacion,
    tipo_doc: bill.tipo_doc || userData?.tipo_doc,
    reference: bill.reference,
    concept: bill.concept,
    currency: bill.currency && bill.currency !== '' ? bill.currency : 'COP',
    amount: bill.amount,
    pay_amount:
      bills.length === 1 ? amountFromForm : bill.pay_amount > 0 ? bill.pay_amount : bill.amount,
  }))
}

export function* PAY_TICKET_TC({ payload: values }) {
  const { payMethod } = store.getState()
  yield putAction(setPayTicketCommerce({ loading: true }))
  yield putAction(setPayTicketCommerce({ payTicket: null }))
  yield putAction(setPayTicketCommerce({ responseTC: '' }))

  const billSaved = values.invoice // Trae valores precargados de TC desde el admin
  const commerceSelect = values.commerce
  const billCaptured = values.invoiceCaptured // Valores de información de pago para TC basica
  const creditCardInfo = values.tc // Valores capturados de información de usuario para TC Basica
  const userDataInfo = values.userData
  const ticketTC = {}

  let departamento = ''

  // console.log(billSaved)

  ticketTC.commerce_id = commerceSelect.commerce_id
  ticketTC.currency = 'COP'

  if (billSaved?.length) {
    ticketTC.amount =
      billSaved.length > 1 ? Number(getInvoicesTotalPay(billSaved)) : Number(creditCardInfo.amount)
    ticketTC.tickets = cleanInvoices(billSaved, userDataInfo, creditCardInfo.amount)
    ticketTC.currency = billSaved[0].currency
  }

  if (billCaptured && billCaptured.numberPaper && billCaptured.totalPay && billCaptured.currency) {
    ticketTC.form_field = getFormField(billCaptured)
    ticketTC.amount = Number(creditCardInfo.amount) || Number(billCaptured.totalPay)
    ticketTC.reference = billCaptured.numberPaper
    ticketTC.concept = billCaptured.concept
    ticketTC.currency = billCaptured.currency
  }

  payMethod.deptos.map(item => {
    if (item.code === creditCardInfo.departamento) {
      departamento = item.name
    }
    return departamento
  })

  ticketTC.address = `${creditCardInfo.addressSelect || ''} ${creditCardInfo.address} #${
    creditCardInfo.numAddress
  }-${creditCardInfo.numIntAddress}, ${creditCardInfo.apartament}. ${creditCardInfo.city ||
    ''}, ${departamento || ''}, ${creditCardInfo.country}`
  ticketTC.postal_code = String(creditCardInfo.postal_code)
  ticketTC.name = creditCardInfo.name
  ticketTC.holder_name = creditCardInfo.name
  ticketTC.card_number = String(creditCardInfo.card_number)
  ticketTC.cvc = String(creditCardInfo.cvc)
  ticketTC.expiry_month = Number(moment(creditCardInfo.expiry_date).format('MM'))
  ticketTC.expiry_year = Number(moment(creditCardInfo.expiry_date).format('YYYY'))
  ticketTC.installments = Number(creditCardInfo.installments)
  ticketTC.document_number = creditCardInfo.numDoc
  ticketTC.phone = String(creditCardInfo.phone)
  ticketTC.email = creditCardInfo.email
  ticketTC.document_type_id = creditCardInfo.tipoDoc
  ticketTC.captcha = creditCardInfo.token
  ticketTC.privacy = creditCardInfo.termsAndConditions

  try {
    const success = yield call(payTicketData, ticketTC)
    yield putAction(setPayTicketCommerce({ payTicket: success.data }))
    yield putAction(setPayTicketCommerce({ responseTC: 'success' }))
  } catch (error) {
    console.log(error)
    yield putAction(setPayTicketCommerce({ responseTC: 'error' }))
  }
  yield putAction(setPayTicketCommerce({ loading: false }))
}

export function* PAY_TICKET_PSE({ payload: values }) {
  yield putAction(setPayTicketCommerce({ loading: true }))
  yield putAction(setPayTicketCommerce({ payTicket: null }))
  yield putAction(setPayTicketCommerce({ responsePSE: '' }))
  const facturaGuardada = values.factura
  const facturaCapturadas = values.facturaCapturada
  const datosPago = values.pse
  const commerceSelect = values.commerce
  const userDataInfo = values.userData

  const ticket = { facturaCapturadas }

  ticket.commerce_id = commerceSelect.commerce_id
  ticket.currency = 'COP'

  if (facturaGuardada?.length) {
    ticket.amount =
      facturaGuardada.length > 1
        ? Number(getInvoicesTotalPay(facturaGuardada))
        : Number(datosPago.amount)
    ticket.tickets = cleanInvoices(facturaGuardada, userDataInfo, datosPago.amount)
    ticket.currency = facturaGuardada[0].currency
  }

  if (facturaCapturadas?.numberPaper) {
    ticket.reference = facturaCapturadas.numberPaper
    ticket.concept = facturaCapturadas.concept
    ticket.form_field = getFormField(facturaCapturadas)
    ticket.amount = Number(datosPago.amount || facturaCapturadas.totalPay)
    ticket.currency = facturaCapturadas.currency
  }

  ticket.document_type = datosPago.tipoDoc
  ticket.document_number = datosPago.numDoc
  ticket.name = datosPago.name || facturaGuardada.name
  ticket.phone = datosPago.phone || facturaGuardada.phone
  ticket.email = datosPago.email || facturaGuardada.email

  ticket.pse = {
    bank_code: datosPago.banco,
    response_url: '/ticket/comercio/',
    type_user: datosPago.person,
    ip_address: '196.180.50.10',
    type_doc: datosPago.tipoDoc.toUpperCase(),
    num_doc: datosPago.numDoc,
  }
  ticket.privacy = datosPago.termsAndConditions
  ticket.captcha = datosPago.token
  // console.log(ticket)
  try {
    const success = yield call(payTicketData, ticket)
    yield putAction(setPayTicketCommerce({ payTicket: success.data }))
  } catch (error) {
    console.log(error)
    yield putAction(setPayTicketCommerce({ responsePSE: 'error' }))
  }
  yield putAction(setPayTicketCommerce({ loading: false }))
}

function getFormField(data) {
  const result = {}
  let formfield = false
  Object.keys(data).forEach(key => {
    if (key.startsWith('form_')) {
      const field = key.replace('form_', 'A')
      result[field] = data[key]
      formfield = true
    }
  })

  if (formfield) {
    return result
  }
  return null
}
export function* RETURN_PAY_TICKET() {
  yield putAction(setPayTicketCommerce({ loading: false }))
  yield putAction(setPayTicketCommerce({ payTicket: null }))
  yield putAction(setPayTicketCommerce({ responseTC: '' }))
  yield putAction(setPayTicketCommerce({ responsePSE: '' }))
}
export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_PAY, PAY_TICKET_TC)])
  yield all([takeEvery(actions.LOAD_PAY_PSE, PAY_TICKET_PSE)])
  yield all([takeEvery(actions.RETURN_PAY_TICKET, RETURN_PAY_TICKET)])
}
