import { privateAxios, privateAxiosForm, publicAxios } from 'services'
import endpoints from 'constants/endpoints'

export function createFactura(datos) {
  return privateAxios().request({
    method: 'post',
    url: endpoints.TICKET.CREATE,
    data: datos,
  })
}

export function createFacturaFile(datos) {
  return privateAxiosForm().request({
    method: 'post',
    url: endpoints.TICKET.IMPORT,
    data: datos,
  })
}

export function getFactura(datos) {
  return publicAxios().request({
    method: 'post',
    url: `${endpoints.TICKET.GET_BY_COMMERCE_BY_PERSON}${datos.commerce_id}/client/`,
    data: { id_ticket: datos.document_id, key_ticket: datos.document_key },
  })
}

export function getDataFiduciary(datos) {
  console.log('Datos get fiduciaria', datos)
  return publicAxios().request({
    method: 'post',
    url: `${endpoints.TICKET.GET_BY_COMMERCE_BY_PERSON}${datos.commerce_id}/client/`,
    data: {
      id_ticket: datos.id_ticket,
      key_ticket: datos.key_ticket,
      amount: datos.amount ? Number(datos.amount) : 0,
    },
  })
}

export default { createFactura }
