import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getCommerce, updateCredentials, updateCommerce, newPayMethod } from 'services/commerce'
import { SITE_MESSAGE } from 'constants/site_message'
import { safeSagaRequest } from 'helpers/safeSaga'
import { ACTION_configLoading } from 'models/redux/config/actions'
import actions, { ACTION_SAGA_loadCommerce } from './actions'

export function* LOAD_ASYNC({ payload }) {
  // todo agregar la url con los parametros recibidos
  //console.log(payload)
  yield put(ACTION_SAGA_loadCommerce({}))
  yield put({
    type: actions.CHANGE_VALUE,
    payload: { loading: true, buscandoComercio: true, comercioEncontrado: false },
  })
  yield put(ACTION_configLoading({ loading: true }))
  try {
    const success = yield call(getCommerce, payload)
    yield put(ACTION_SAGA_loadCommerce(success.data))
    yield put(
      ACTION_configLoading({
        loading: false,
        message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
      }),
    )
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { loading: false, buscandoComercio: false, comercioEncontrado: true },
    })
  } catch (error) {
    console.log(error)
    yield put(ACTION_configLoading({ loading: false }))
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { loading: false, buscandoComercio: false, comercioEncontrado: false },
    })
  }
}

export function* UPDATE_CREDENTIALS({ payload: data }) {
  //console.log(data)
  yield put({
    type: actions.CHANGE_VALUE,
    payload: { success: false, loading: true, onError: false },
  })
  try {
    const success = yield call(updateCredentials, data)
    if (success.data) {
      yield put({
        type: actions.CHANGE_VALUE,
        payload: { success: true, loading: false, onError: false },
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { success: false, loading: false, onError: true },
    })
  }
}

export function* UPDATE_COMMERCE({ payload: data }) {
  console.log(data)
  yield put({
    type: actions.CHANGE_VALUE,
    payload: { success: false, loading: true, onError: false },
  })
  try {
    const success = yield call(updateCommerce, data)
    if (success.data) {
      yield put({
        type: actions.CHANGE_VALUE,
        payload: { success: true, loading: false, onError: false },
      })
      yield put(ACTION_SAGA_loadCommerce(success.data))
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { success: false, loading: false, onError: true },
    })
  }
}

export function* NEW_PAY_METHOD({ payload: data }) {
  yield put({
    type: actions.CHANGE_VALUE,
    payload: { success: false, loading: true, onError: false },
  })
  try {
    const success = yield call(newPayMethod, data)
    if (success.data) {
      yield put({
        type: actions.CHANGE_VALUE,
        payload: { success: true, loading: false, onError: false },
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { success: false, loading: false, onError: true },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.LOAD_COMMERCE_ASYNC,
      safeSagaRequest(LOAD_ASYNC, actions.LOAD_COMMERCE_ASYNC),
    ),
    takeEvery(
      actions.UPDATE_CREDENTIALS,
      safeSagaRequest(UPDATE_CREDENTIALS, actions.UPDATE_CREDENTIALS),
    ),
    takeEvery(actions.UPDATE_COMMERCE, safeSagaRequest(UPDATE_COMMERCE, actions.UPDATE_COMMERCE)),
    takeEvery(
      actions.ADD_NEW_PAYMETHOD,
      safeSagaRequest(NEW_PAY_METHOD, actions.ADD_NEW_PAYMETHOD),
    ),
  ])
}
