import actions from './actions'

const initialState = {
  loadHistorialTransactions: false,
  loadActivitySchedule: false,
  loadTransactionsStatus: false,
  loadAppCode: false,
  loadTypeReject: false,
  historialTransactions: null,
  activitySchedule: null,
  transactionsStatus: null,
  appCode: null,
  typeReject: null,
}

export default function useReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_HISTORIAL_TRANSACTIONS: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.LOAD_ACTIVITY_SCHEDULE: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.LOAD_TRANSACTIONS_STATUS: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.LOAD_APP_CODE: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.LOAD_TYPE_REJECT: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    default:
      return state
  }
}
