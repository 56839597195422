const actions = {
  LOAD: 'commercePublic/LOAD',
  COMMERCE_DATA: 'commercePublic/COMMERCE_DATA',
  SET_COMMERCE_DATA: 'commercePublic/SET_COMMERCE_DATA',
  COMMERCE_OPTIONS_DATA: 'commercePublic/COMMERCE_OPTIONS_DATA',
  COMMERCE_GET_FACTURA: 'commercePublic/COMMERCE_GET_FACTURA',
  COMMERCE_SET_INVOICES: 'commercePublic/COMMERCE_SET_INVOICES',
  SET_COMMERCE_TICKET_DATA: 'commercePublic/SET_COMMERCE_TICKET_DATA',
  COMMERCE_TRANSACTION_DATA: 'commercePublic/COMMERCE_TRANSACTION_DATA',
  EXCHANGE_VALUE: 'commercePublic/EXCHANGE_VALUE',
  SET_EXCHANGE_VALUE: 'commercePublic/GET_EXCHANGE_VALUE',
  LOAD_CACHE_COMMERCE: 'commerce/LOAD_CACHE_COMMERCE',
  SET_COMMERCE_UPDATE_FORM_VALID_STATUS: 'commerce/SET_COMMERCE_UPDATE_FORM_VALID_STATUS',
  COMMERCE_UPDATE_FORM_VALID_STATUS: 'commerce/COMMERCE_UPDATE_FORM_VALID_STATUS',
  SET_PAYMENT_FIDUCIARY: 'commerce/SET_PAYMENT_FIDUCIARY',
  GET_PAYMENT_FIDUCIARY: 'commerce/GET_PAYMENT_FIDUCIARY',
  SET_COMMERCE_INTEGRATION: 'commerce/SET_COMMERCE_INTEGRATION',
  COMMERCE_INTEGRATION: 'commerce/COMMERCE_INTEGRATION',
  CLEAR_CHARGES_DATA: 'commercePublic/CLEAR_CHARGES_DATA',
  CLEAR_PENDING_INVOICES: 'commercePublic/CLEAR_PENDING_INVOICES_DATA',
}

export const currencyExchangeData = data => ({
  type: actions.EXCHANGE_VALUE,
  payload: data,
})

export const setCurrencyExchangeData = data => ({
  type: actions.SET_EXCHANGE_VALUE,
  payload: data,
})

export const commercePublicData = data => ({
  type: actions.COMMERCE_DATA,
  payload: data,
})

export const setCommercePublicData = data => ({
  type: actions.SET_COMMERCE_DATA,
  payload: data,
})

export const getOptionsCommercePublicData = data => ({
  type: actions.COMMERCE_OPTIONS_DATA,
  payload: data,
})

export const getFacturaCommercePublic = data => ({
  type: actions.COMMERCE_GET_FACTURA,
  payload: data,
})

export const commercePublicTicketData = data => ({
  type: actions.SET_COMMERCE_TICKET_DATA,
  payload: data,
})

export const setInvoicesCommercePublic = data => ({
  type: actions.COMMERCE_SET_INVOICES,
  payload: data,
})

export const commercePublicTransactionData = data => ({
  type: actions.COMMERCE_TRANSACTION_DATA,
  payload: data,
})

export const ACTION_SAGA_ComercioConsultado = data => ({
  type: actions.LOAD_CACHE_COMMERCE,
  payload: data,
})

export const updateCommerceValidationStatus = data => ({
  type: actions.SET_COMMERCE_UPDATE_FORM_VALID_STATUS,
  payload: data,
})

export const setFiduciary = data => ({
  type: actions.SET_PAYMENT_FIDUCIARY,
  payload: data,
})

export const getFiduciary = data => ({
  type: actions.GET_PAYMENT_FIDUCIARY,
  payload: data,
})

export const setIntegrationCommerce = data => ({
  type: actions.SET_COMMERCE_INTEGRATION,
  payload: data,
})

export const getIntegrationCommerce = data => ({
  type: actions.COMMERCE_INTEGRATION,
  payload: data,
})

export const clearInvoicesCommercePublic = data => ({
  type: actions.CLEAR_CHARGES_DATA,
  payload: data,
})

export const setPendingCommercePublic = data => ({
  type: actions.CLEAR_PENDING_INVOICES,
  payload: data,
})

export default actions
