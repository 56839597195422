const actions = {
  LOAD: 'commerceStatus/LOAD',
  LOAD_ASYNC: 'commerceStatus/LOAD_ASYNC',
  LOAD_COMMERCE: 'commerceStatus/LOAD_LOAD_COMMERCE',
  LOAD_COMMERCE_ASYNC: 'commerceStatus/LOAD_LOAD_COMMERCE_ASYNC',
  CHANGE_VALUE: 'commerceStatus/CHANGE_VALUE',
  UPDATE_CREDENTIALS: 'commerceStatus/UPDATE_CREDENTIALS',
  UPDATE_COMMERCE: 'commerceStatus/UPDATE_COMMERCE',
  COMMERCE_SELECT: 'commerceStatus/COMMERCE_SELECT',
  COMMERCE_SELECT_PAYMETHOD: 'commerceStatus/COMMERCE_SELECT_PAYMETHOD',
  ADD_NEW_PAYMETHOD: 'commerceStatus/ADD_NEW_PAYMETHOD',
}

export const ACTION_LoadCommerce = data => ({
  type: actions.LOAD_COMMERCE_ASYNC,
  payload: data,
})

export const ACTION_SAGA_loadCommerce = (commerce = []) => ({
  type: actions.LOAD_COMMERCE,
  payload: commerce,
})

export const ACTION_updateCredentials = data => ({
  type: actions.UPDATE_CREDENTIALS,
  payload: data,
})

export const ACTION_updateComerce = data => ({
  type: actions.UPDATE_COMMERCE,
  payload: data,
})

export const ACTION_closeDialog = () => ({
  type: actions.CHANGE_VALUE,
  payload: { success: false, onError: false },
})

export const ACTION_changeValue = data => ({
  type: actions.CHANGE_VALUE,
  payload: data,
})

export const ACTION_commerceSelect = data => ({
  type: actions.COMMERCE_SELECT,
  payload: data,
})
export const ACTION_commerceSelectPayMethod = data => ({
  type: actions.COMMERCE_SELECT_PAYMETHOD,
  payload: data,
})
export const ACTION_newPayMethod = data => ({
  type: actions.ADD_NEW_PAYMETHOD,
  payload: data,
})
export default actions
