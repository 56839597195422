const actions = {
  LOAD_ASYNC_CATEGORIES: 'categories/LOAD_ASYNC_CATEGORIES',
  ASYNC_CATEGORY_CREATE: 'categories/ASYNC_CATEGORY_CREATE',
  CATEGORY_UPDATE: 'categories/CATEGORY_UPDATE',
  CATEGORY_DELETE: 'categories/CATEGORY_DELETE',
  ASYNC_CATEGORIES_UPDATE_ORDER: 'categories/ASYNC_CATEGORIES_UPDATE_ORDER',
}

export const ACTION_SAGA_CategoriesGet = (categories = []) => ({
  type: actions.LOAD_ASYNC_CATEGORIES,
  payload: categories,
})

export const ACTION_SAGA_CategoryCreate = categoryData => ({
  type: actions.CATEGORY_CREATE,
  payload: categoryData,
})

export const ACTION_SAGA_CategoryUpdate = categoryData => ({
  type: actions.CATEGORY_UPDATE,
  payload: categoryData,
})

export const ACTION_SAGA_CategoryDelete = categoryId => ({
  type: actions.CATEGORY_DELETE,
  payload: categoryId,
})

export const ACTION_SAGA_CategoryUpdateOrder = categories => ({
  type: actions.ASYNC_CATEGORIES_UPDATE_ORDER,
  payload: categories,
})

export default actions
