import actions from './actions'

const initialState = {
  loading: false,
  commercePublicData: {},
  documents: [],
  paymentMethods: [],
  docLoading: false,
  facturasPending: [],
  selectedInvoices: [],
  banks: [],
  infoSummary: null,
  transaction: null,
  exchangeValue: 0,
  paymentFormValidStatus: false,
  paymentFiduciary: null,
  commerceIntegrationData: {},
  fiduciariaConsultada: false,
  errorFiduciaria: null,
}

export default function useReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.SET_COMMERCE_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case actions.COMMERCE_SET_INVOICES: {
      return {
        ...state,
        selectedInvoices: action.payload,
      }
    }
    case actions.SET_EXCHANGE_VALUE: {
      return {
        ...state,
        exchangeValue: action.payload,
      }
    }
    case actions.SET_COMMERCE_TICKET_DATA: {
      return {
        ...state,
        infoSummary: action.payload,
      }
    }
    case actions.SET_COMMERCE_UPDATE_FORM_VALID_STATUS: {
      return {
        ...state,
        paymentFormValidStatus: action.payload,
      }
    }
    case actions.SET_COMMERCE_INTEGRATION: {
      return {
        ...state,
        commerceIntegrationData: action.payload,
      }
    }
    case actions.SET_PAYMENT_FIDUCIARY: {
      return {
        ...state,
        paymentFiduciary: action.payload,
      }
    }
    default:
      return state
  }
}
