import { all } from 'redux-saga/effects'
import user from './user/sagas'
import commerce from './commerce/sagas'
import payMethod from './payMethod/sagas'
import payTicket from './payTicket/sagas'
import general from './general/sagas'
import factura from './factura/sagas'
import commercePublic from './commercePublic/sagas'
import resume from './resume/sagas'
import transactions from './transactions/sagas'
import commerceStatus from './commerceStatus/sagas'
import usuarios from './usuarios/sagas'
import reportes from './reportes/sagas'
import categories from './categories/sagas'
import retries from './retries/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    commerce(),
    payMethod(),
    payTicket(),
    general(),
    factura(),
    commercePublic(),
    resume(),
    transactions(),
    commerceStatus(),
    usuarios(),
    reportes(),
    categories(),
    retries(),
  ])
}
