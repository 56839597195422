import { privateAxios } from 'services'
import endpoints from 'constants/endpoints'

const qs = require('qs')

export const historialTransactionsService = params =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.RESUME.HISTORIAL_TRANSACTIONS}`,
    params,
    paramsSerializer: paramsT => {
      return qs.stringify(paramsT, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export const activityScheduleService = params =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.RESUME.ACTIVITY_SCHEDULE}`,
    params,
    paramsSerializer: paramsT => {
      return qs.stringify(paramsT, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export const transactionsStatusService = params =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.RESUME.TRANSACTIONS_STATUS}`,
    params,
    paramsSerializer: paramsT => {
      return qs.stringify(paramsT, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export const appCodeService = params =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.RESUME.APP_CODE}`,
    params,
    paramsSerializer: paramsT => {
      return qs.stringify(paramsT, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export const typeRejectService = params =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.RESUME.TYPE_REJECT}`,
    params,
    paramsSerializer: paramsT => {
      return qs.stringify(paramsT, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export default {
  historialTransactionsService,
  activityScheduleService,
  transactionsStatusService,
  appCodeService,
  typeRejectService,
}
