import actions from './actions'

/**
 * Referirse a src/assets/json/commerce.json para revisar estructura de array
 */

const initialState = {
  commerces: [],
  commerceSelect: {},
  loading: false,
  success: false,
  onError: false,
  buscandoComercio: false,
  comercioEncontrado: false,
  commerceDetail: false,
  addPayMethod: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD: {
      const { payload = [] } = action
      return {
        ...state,
        commerces: payload,
      }
    }
    case actions.LOAD_COMMERCE: {
      const { payload = [] } = action
      return {
        ...state,
        commerceSelect: payload,
      }
    }
    case actions.CHANGE_VALUE: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    case actions.COMMERCE_SELECT: {
      const { payload = [] } = action
      return {
        ...state,
        commerceDetail: payload,
      }
    }
    case actions.COMMERCE_SELECT_PAYMETHOD: {
      const { payload = [] } = action
      return {
        ...state,
        addPayMethod: payload,
      }
    }
    default:
      return state
  }
}
