export default {}

export const ENVIROMENT = process.env.NODE_ENV
export const HOST_SITE_ENDPOINTS = ''
export const SITE_NAME = process.env.REACT_APP_PORTALPAGOS_SITE_NAME || 'Web Site'
export const LOCALSTORAGE_SESSION_ATTRIBUTE = 'user'
export const RECAPTCHA_KEY = process.env.REACT_APP_PORTALPAGOS_RECAPTCHA_SITE_KEY
export const JWT_SECRET = process.env.REACT_APP_PORTALPAGOS_JWT_SECRET
export const ENCRYPT_KEY_REQUEST = process.env.REACT_APP_PORTALPAGOS_ENCRYPT_KEY_REQUEST
export const TIME_IDLE = process.env.REACT_APP_TIME_IDLE
  ? Number(process.env.REACT_APP_TIME_IDLE)
  : 20
export const TIME_TOKEN = process.env.REACT_APP_TIME_TOKEN
  ? Number(process.env.REACT_APP_TIME_TOKEN)
  : 50
export const TERMS_AND_CONDITIONS_URL =
  process.env.REACT_APP_TERMS_AND_CONDITIONS || 'https://www.davivienda.com/'
export const TIME_EXPIRED = process.env.REACT_APP_TIME_EXPIRED
  ? Number(process.env.REACT_APP_TIME_EXPIRED)
  : 60
export const EXTRA_APPCODE = process.env.REACT_APP_EXTRA_APPCODE
  ? Number(process.env.REACT_APP_EXTRA_APPCODE)
  : 50
