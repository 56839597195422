const actions = {
  LOAD: 'commerce/LOAD',
  LOAD_ASYNC: 'commerce/LOAD_ASYNC',
  LOAD_ASYNC_CATEGORY: 'commerce/LOAD_ASYNC_CATEGORY',
  LOAD_CATEGORY: 'commerce/LOAD_CATEGORY',
  LOAD_ASYNC_COMMERCE_CATEGORY: 'commerce/LOAD_ASYNC_COMMERCE_CATEGORY',
  LOAD_CATEGORIZED_COMMERCE: 'commerce/LOAD_CATEGORIZED_COMMERCE',
  LOAD_ASYNC_PRIVATE_COMMERCES: 'comerce/LOAD_ASYNC_PRIVATE_COMMERCES',
  LOAD_LIST_COMMERCES_DELETED: 'comerce/LOAD_LIST_COMMERCES_DELETED',
  LOAD_LIST_TABLE_LOGS: 'commerce/LOAD_LIST_TABLE_LOGS',
  LOAD_LIST_COUNTRIES: 'commerce/LOAD_LIST_COUNTRIES',
  UPDATE_LIST_EXCLUDE_COUNTRIES: 'commerce/UPDATE_LIST_EXCLUDE_COUNTRIES',
  LOAD_LIST_EXCLUDE_COUNTRIES: 'commerce/LOAD_LIST_EXCLUDE_COUNTRIES',
}

export const ACTION_LoadCommerce = () => ({
  type: actions.LOAD_ASYNC,
})

export const ACTION_LoadCategories = () => ({
  type: actions.LOAD_ASYNC_CATEGORY,
})

export const ACTION_LoadCommerceCategories = () => ({
  type: actions.LOAD_ASYNC_COMMERCE_CATEGORY,
})

export const ACTION_SAGA_loadCommerce = (commerce = []) => ({
  type: actions.LOAD,
  payload: commerce,
})

export const ACTION_SAGA_loadCategories = (categories = []) => ({
  type: actions.LOAD_CATEGORY,
  payload: categories,
})

export const ACTION_SAGA_loadCategorizedCommerce = (groupedCommerce = { 0: [] }) => ({
  type: actions.LOAD_CATEGORIZED_COMMERCE,
  payload: groupedCommerce,
})

// GET PRIVATE COMMERCES
export const ACTION_SAGA_loadPrivateCommerces = (commerces = []) => ({
  type: actions.LOAD_ASYNC_PRIVATE_COMMERCES,
  payload: commerces,
})

// Lista de comercios eliminados
export const ACTION_SAGA_listCommercesDeleted = (commerces = []) => ({
  type: actions.LOAD_LIST_COMMERCES_DELETED,
  payload: commerces,
})

export const ACTION_updateListExcludeCountries = data => ({
  type: actions.UPDATE_LIST_EXCLUDE_COUNTRIES,
  payload: data,
})

// Bitácora
export const ACTION_SAGA_tableLogs = (info = []) => ({
  type: actions.LOAD_LIST_TABLE_LOGS,
  payload: info,
})

//obtener lista de paises
export const ACTION_SAGA_loadListCountries = (countries = []) => ({
  type: actions.LOAD_LIST_COUNTRIES,
  payload: countries,
})

//obtener lista de paises excluido
export const ACTION_SAGA_loadListExcludedCountries = (excludeCountries = []) => ({
  type: actions.LOAD_LIST_EXCLUDE_COUNTRIES,
  payload: excludeCountries,
})

export default actions
