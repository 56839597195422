import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getUser, getCommerces, crateUser, updateUser } from 'services/user'
import { SITE_MESSAGE } from 'constants/site_message'
import { safeSagaRequest } from 'helpers/safeSaga'
import actions from './actions'
import { ACTION_configLoading } from '../config/actions'

export function* GET_USUARIO({ payload }) {
  //console.log(payload)
  yield put({
    type: actions.CHANGE_VALUE,
    payload: { userSelect: {} },
  })
  yield put(ACTION_configLoading({ loading: true }))
  try {
    const success = yield call(getUser, payload)
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { userSelect: success.data },
    })
    yield put(
      ACTION_configLoading({
        loading: false,
        message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
      }),
    )
  } catch (error) {
    console.log(error)
    yield put(ACTION_configLoading({ loading: false }))
  }
}

export function* LOAD_COMMERCES_ASYNC() {
  yield put({
    type: actions.CHANGE_VALUE,
    payload: { commerces: [] },
  })
  yield put(ACTION_configLoading({ loading: true }))
  try {
    const success = yield call(getCommerces)
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { commerces: success.data },
    })
    yield put(
      ACTION_configLoading({
        loading: false,
        message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
      }),
    )
  } catch (error) {
    console.log(error)
    yield put(ACTION_configLoading({ loading: false }))
  }
}

export function* CREATE_USER_ASYNC({ payload }) {
  yield put(ACTION_configLoading())
  yield put({
    type: actions.CHANGE_VALUE,
    payload: { success: false, loading: true, onError: false },
  })
  try {
    const success = yield call(crateUser, payload)
    if (success.data) {
      yield put({
        type: actions.CHANGE_VALUE,
        payload: { success: true, loading: false, onError: false },
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { success: false, loading: false, onError: true },
    })
  }
  yield put(
    ACTION_configLoading({
      loading: false,
      message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
    }),
  )
}

export function* UPDATE_USER_ASYNC({ payload }) {
  yield put(ACTION_configLoading())
  yield put({
    type: actions.CHANGE_VALUE,
    payload: { success: false, loading: true, onError: false },
  })
  try {
    const success = yield call(updateUser, payload)
    if (success.data) {
      yield put({
        type: actions.CHANGE_VALUE,
        payload: { success: true, loading: false, onError: false },
      })
      yield put({
        type: actions.CHANGE_VALUE,
        payload: { userSelect: success.data },
      })
    }
  } catch (error) {
    console.log(error)
    yield put({
      type: actions.CHANGE_VALUE,
      payload: { success: false, loading: false, onError: true },
    })
  }
  yield put(
    ACTION_configLoading({
      loading: false,
      message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP },
    }),
  )
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_USER_ASYNC, safeSagaRequest(GET_USUARIO, actions.LOAD_USER_ASYNC)),
    takeEvery(
      actions.LOAD_COMMERCES_ASYNC,
      safeSagaRequest(LOAD_COMMERCES_ASYNC, actions.LOAD_COMMERCES_ASYNC),
    ),
    takeEvery(
      actions.CREATE_USER_ASYNC,
      safeSagaRequest(CREATE_USER_ASYNC, actions.CREATE_USER_ASYNC),
    ),
    takeEvery(
      actions.UPDATE_USER_ASYNC,
      safeSagaRequest(UPDATE_USER_ASYNC, actions.UPDATE_USER_ASYNC),
    ),
  ])
}
