import { privateAxios } from 'services'
import endpoints from 'constants/endpoints'

const qs = require('qs')

export const initReports = params =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.REPORTS.INIT_REPORTS}`,
    params,
    paramsSerializer: cParameters => {
      return qs.stringify(cParameters, {
        arrayFormat: 'repeat',
        encode: false,
        skipNulls: true,
      })
    },
  })

export const findReports = parametros =>
  privateAxios().request({
    method: 'get',
    url: endpoints.REPORTS.GET_ALL,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export const tableEvents = parametros =>
  privateAxios().request({
    method: 'get',
    url: endpoints.REPORTS.GET_EVENTS_COMMERCES,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat', encode: false, skipNulls: true })
    },
  })

export const eventName = () =>
  privateAxios().request({
    method: 'get',
    url: endpoints.REPORTS.GET_NAME_EVENTS,
  })

export const initReportEvent = params => {
  console.log(params)
  let urlFinal = `${endpoints.REPORTS.EXPORT_EVENTS}`
  if (params.tipo === 'comercio') {
    urlFinal = `${endpoints.REPORTS.EXPORT_EVENTS_COMMERCE}`
  }
  return privateAxios().request({
    method: 'get',
    url: urlFinal,
    params,
    paramsSerializer: cParameters => {
      console.log('reporte')
      return qs.stringify(cParameters, {
        arrayFormat: 'repeat',
      })
    },
  })
}

export const closeEvnto = params =>
  privateAxios().request({
    method: 'post',
    url: `${endpoints.REPORTS.CLOSE_EVENTS}/${params}`,
  })

export default {
  findReports,
  initReports,
  tableEvents,
  eventName,
  initReportEvent,
  closeEvnto,
}
