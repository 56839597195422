import endpoints from 'constants/endpoints'
import { publicAxios, privateAxios } from 'services'
import { isEmpty } from 'helpers/isEmpty'
//import { encryptedRequest } from 'utils/encrypted'

const qs = require('qs')

/*eslint no-constant-condition: 0*/

// Moved api call into own function (for easy test swapping)
export function login({ credential, password }) {
  //const dataEncrypted = encryptedRequest({ credential, password })
  return publicAxios().request({
    method: 'post',
    url: `${endpoints.AUTH.LOGIN}`,
    //data: { data : "yRvp1E2lQMlC6aEBKpsjLst4iMcVb4W0k7RBm-osH-X-vb3yMW5udYw-6WVSaaCv85cSakemeaa6t3ReTfC8xQoG4uENTsPDHyaPQPl01owGdKMKK4TRSQ==" },
    data: { credential, password },
  })
}

export function loginTest({ email, password }) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const random = Math.floor(Math.random() * 100)
      if (random > 4) {
        resolve({
          data: { token: 'TEST', email, password },
        })
      } else {
        reject(new Error('Emulate token error'))
      }
    })
  })
}

export function verifyAccount({ token, ...data }) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      //const random = Math.floor(Math.random() * 100)
      if (!isEmpty(token)) {
        resolve({
          data: {
            token,
            ...data,
          },
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {},
          request: {},
        })
      } else {
        reject(new Error('Emulate token error'))
      }
    })
  })
}

export function forgot({ email, captcha }) {
  return publicAxios().request({
    method: 'post',
    url: `${endpoints.AUTH.FORGOT}`,
    data: { email, captcha },
  })
}

export function changePassword({ identifier, password, captcha }) {
  //const dataEncrypted = encryptedRequest({ identifier, password, captcha })
  return publicAxios().request({
    method: 'post',
    url: `${endpoints.AUTH.CHANGE_PASSWORD}`,
    //data: { data: dataEncrypted },
    data: { identifier, password, captcha },
  })
}

export const findUsers = parametros =>
  privateAxios().request({
    method: 'get',
    url: endpoints.USER.GET_ALL,
    params: parametros,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

export const getUser = id =>
  privateAxios().request({
    method: 'get',
    url: endpoints.USER.GET + id,
  })

export const getCommerces = () =>
  privateAxios().request({
    method: 'get',
    url: endpoints.COMMERCE.GET,
  })

export const crateUser = data =>
  privateAxios().request({
    method: 'post',
    url: endpoints.USER.CREATE,
    data,
  })

export const updateUser = data =>
  privateAxios().request({
    method: 'put',
    url: endpoints.USER.UPDATE,
    data,
  })

export const deleteUser = id =>
  privateAxios().request({
    method: 'delete',
    url: `${endpoints.USER.DELETE_USER}/${id}`,
  })
export const tokenReset = () =>
  privateAxios().request({
    method: 'get',
    url: `${endpoints.USER.TOKEN_UPDATE}`,
  })

export const createPerfil = data =>
  privateAxios().request({
    method: 'post',
    url: `${endpoints.USER.PERFIL_CREATE}`,
    data,
  })
export const updatePerfil = data =>
  privateAxios().request({
    method: 'put',
    url: `${endpoints.USER.PERFIL_UPDATE}`,
    data,
  })
export default {}
