/* eslint-disable no-restricted-syntax */
import { all, takeEvery, put as putAction, call } from 'redux-saga/effects'
import { safeSagaRequest } from 'helpers/safeSaga'
import { tableTransactions } from '../../../services/transactions'
import actions, { ACTION_LOAD_TRANSACTIONS } from './actions'

export function* TABLE_TRANSACTION({ payload }) {
  yield putAction(ACTION_LOAD_TRANSACTIONS({ tableLoading: true }))
  const params = payload

  try {
    // const success = yield call(historialTransactions('?application_code=123'))
    const success = yield call(tableTransactions, params)
    yield putAction(ACTION_LOAD_TRANSACTIONS({ tableTransactions: success.data }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(ACTION_LOAD_TRANSACTIONS({ tableLoading: false }))
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.TABLE_TRANSACTIONS,
      safeSagaRequest(TABLE_TRANSACTION, actions.TABLE_TRANSACTIONS),
    ),
  ])
}
