export const getInvoicesTotalPay = (invoices = [], integration = '') => {
  if (!Array.isArray(invoices)) {
    return null
  }
  if (!invoices.length) {
    return 0
  }
  if (integration === 'credito' || integration === 'tarjeta_credito') {
    return invoices[0].min_amount
  }

  if (invoices[0].pay_amount !== 0 && invoices[0].pay_amount !== undefined) {
    const amountSum = invoices.reduce((valorAnterior, valorActual) => {
      return valorAnterior + (valorActual?.pay_amount || 0)
    }, 0)
    return amountSum || invoices[0].pay_amount
  }
  if (invoices.length === 1) {
    return invoices[0].amount
  }
  const amountSum = invoices.reduce((valorAnterior, valorActual) => {
    //console.log(valorActual, 'valor actual')
    return valorAnterior + (valorActual?.amount || 0)
  }, 0)
  return amountSum || invoices[0].amount
}

export const isSameCurrency = (invoices = null) =>
  Array.isArray(invoices) && invoices[0]?.currency
    ? !invoices?.some(invoice => {
        //console.log("validando facturas", invoice, invoices[0].currency)
        return 'currency' in invoice && invoice.currency !== invoices[0].currency
      })
    : false

const getReducedData = (data, attr) => {
  return data.map(elm => elm[attr]).join(', ')
}

export const buildInvoicesInfoData = invoices => {
  const invoicesInfoData = {
    reference: getReducedData(invoices, 'reference'),
    concept: getReducedData(invoices, 'concept'),
    name: invoices[0].name,
    email: invoices[0].email,
    phone: invoices[0].phone,
    amount: getInvoicesTotalPay(invoices),
    currency: invoices[0].currency,
    pay_amount: invoices[0].pay_amount,
  }
  return invoicesInfoData
}
