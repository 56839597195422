import actions from './actions'

const initialState = {
  categories: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_ASYNC_CATEGORIES: {
      const { payload = [] } = action
      return {
        ...state,
        categories: [...payload],
      }
    }
    case actions.ASYNC_CATEGORY_CREATE: {
      const { payload = [] } = action
      return {
        ...state,
        categories: payload,
      }
    }
    case actions.CATEGORY_UPDATE: {
      const { payload = [] } = action
      return {
        ...state,
        categories: payload,
      }
    }
    case actions.CATEGORY_DELETE: {
      const { payload = [] } = action
      return {
        ...state,
        categories: payload,
      }
    }
    case actions.ASYNC_CATEGORIES_UPDATE_ORDER: {
      const { payload = [] } = action
      return {
        ...state,
        categories: payload,
      }
    }
    default:
      return state
  }
}
