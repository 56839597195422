import { all, takeEvery, put as putAction, call } from 'redux-saga/effects'
import { initReports, findReports, tableEvents, eventName, initReportEvent } from 'services/report'
import { SITE_MESSAGE } from 'constants/site_message'
import { safeSagaRequest } from 'helpers/safeSaga'
import { ACTION_configLoading } from '../config/actions'

import actions, {
  setExportReports,
  ACTION_LOAD_TABLE_EVENTS,
  loadEventName,
  loadReportEvent,
} from './actions'

export function* INIT_EXPORT_REPORTS({ payload }) {
  // console.log('Paylooooad', payload)
  yield putAction(ACTION_configLoading({ loading: true }))
  yield putAction({
    type: actions.LOAD,
    payload: { reportesLoading: true, success: false, onError: false },
  })

  const params = payload
  try {
    const success = yield call(initReports, params)
    //console.log(success)
    if (success && success.data && success.data && success.data.status) {
      console.log('Success report', success, 'mensaje')
      yield putAction(
        setExportReports({
          exportReportMessage: 'exito',
        }),
      )
    }
    yield putAction({
      type: actions.LOAD,
      payload: { reportesLoading: false, success: true, onError: false },
    })
  } catch (error) {
    console.log(error)
    yield putAction({
      type: actions.LOAD,
      payload: { reportesLoading: false, success: false, onError: true },
    })
  }
}

export function* LOAD_ASYNC() {
  yield putAction(ACTION_configLoading({ loading: true }))
  yield putAction({
    type: actions.LOAD,
    payload: { reportes: [] },
  })
  try {
    const success = yield call(findReports, {
      order: 'created_at',
      order_type: 'desc',
      limit: 1000,
      page: 1,
    })
    yield putAction({
      type: actions.LOAD,
      payload: { reportes: success.data.reports },
    })
    yield putAction(
      ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
    )
  } catch (error) {
    console.log(error)
    yield putAction(ACTION_configLoading({ loading: false }))
  }
}

export function* TABLE_TRANSACTION({ payload }) {
  yield putAction(ACTION_LOAD_TABLE_EVENTS({ tableLoading: true }))
  yield putAction(ACTION_LOAD_TABLE_EVENTS({ tableEventsFilters: payload }))
  const params = payload
  try {
    const succes = yield call(tableEvents, params)
    console.log(succes, 'datos de tabla')
    yield putAction(ACTION_LOAD_TABLE_EVENTS({ tableEvents: succes.data }))
    yield putAction(ACTION_LOAD_TABLE_EVENTS({ tableLoading: false }))
  } catch (error) {
    console.log(error)
  }
}

export function* EVENT_NAME() {
  yield putAction(loadEventName({ loading: true }))
  yield putAction(loadEventName({ eventName: [] }))
  try {
    const success = yield call(eventName)
    yield putAction(loadEventName({ eventName: success.data }))
  } catch (error) {
    console.log(error)
  }
  yield putAction(loadEventName({ loading: false }))
}

export function* INIT_EXPORT_REPORT_EVENT({ payload }) {
  yield putAction(loadReportEvent({ loadingreportesEvent: true }))
  yield putAction({
    type: actions.LOAD_EXPORT_EVENTS,
    payload: { loadingreportesEvent: true, okEvent: false, errorEvent: false },
  })
  const params = payload
  try {
    const success = yield call(initReportEvent, params)
    console.log(success, ' datos de respuesta ')
    const anchor = document.createElement('a')
    anchor.href = success.data
    anchor.setAttribute('download', `${params.name_report}.pdf`)
    //anchor.download = resObj.filename
    document.body.appendChild(anchor)
    anchor.target = '_blank'
    anchor.click()
    document.body.removeChild(anchor)
    yield putAction({
      type: actions.LOAD_EXPORT_EVENTS,
      payload: { loadingreportesEvent: false, okEvent: true, errorEvent: false },
    })
  } catch (error) {
    console.log(error)
    yield putAction({
      type: actions.LOAD_EXPORT_EVENTS,
      payload: { loadingreportesEvent: false, okEvent: false, errorEvent: true },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.EXPORT_REPORTS, safeSagaRequest(INIT_EXPORT_REPORTS, actions.EXPORT_REPORTS)),
    takeEvery(actions.LOAD_ASYNC, safeSagaRequest(LOAD_ASYNC, actions.LOAD_ASYNC)),
    takeEvery(actions.TABLE_EVENTS, safeSagaRequest(TABLE_TRANSACTION, actions.TABLE_EVENTS)),
    takeEvery(actions.SET_NAME_EVENTS, safeSagaRequest(EVENT_NAME, actions.SET_NAME_EVENTS)),
    takeEvery(
      actions.EXPORT_EVENTS,
      safeSagaRequest(INIT_EXPORT_REPORT_EVENT, actions.EXPORT_EVENTS),
    ),
  ])
}
/*
function getParams(data) {
  const params = {}
  const formats = []

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    if (etiquetas[key] && value) {
      if (typeof value === 'string') {
        params[etiquetas[key]] = value
      } else if (typeof value === 'number') {
        params[etiquetas[key]] = value
      } else if (Array.isArray(value)) {
        params[etiquetas[key]] = value
      } else if (key === 'csvFormat' && value) {
        formats.push('csv')
        params.report_format = formats
      } else if (key === 'asoFormat' && value) {
        formats.push('aso')
        params.report_format = formats
      } else {
        params[etiquetas[key]] = value.value
      }
    }
  }
  return params
}

const etiquetas = {
  appCode: 'application_code',
  commerces: 'commerce_id',
  paymentMethod: 'payment_type',
  name: 'name',
  csvFormat: 'report_format',
  asoFormat: 'report_format',
  aso2011Format: 'report_format',
  // all: 'all',
  columns: 'columns',
}*/
