import SITE_MESSAGE from 'constants/site_message'
import commerce from 'models/redux/commerce/actions'
import auth from 'models/redux/user/actions'
import factura from 'models/redux/factura/actions'
import usuarios from 'models/redux/usuarios/actions'

export const data = {
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}`]: 'No pudimos cargar los datos',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${commerce.LOAD_ASYNC_COMMERCE_CATEGORY}`]: 'Verifique que su conexión a Internet esté funcionando correctamente e intente nuevamente',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.LOGIN}-title`]: 'Datos incorrectos',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.LOGIN}`]: 'Verifique usuario y contraseña e intente nuevamente',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${factura.SET_FACTURA}-title`]: 'Cargar Factura',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${factura.SET_FACTURA}`]: 'No se pudo cargar la factura',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${factura.SET_FACTURA_FILE}-title`]: 'Cargar Factura',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${factura.SET_FACTURA_FILE}`]: 'No se pudo cargar el archivo de facturas',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.FORGOT}-title`]: 'Usuario no valido',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.FORGOT}`]: 'No sé encontró el usuario solicitado',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.CHANGE_PASSWORD}-title`]: 'Cambio de password',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.CHANGE_PASSWORD}`]: 'El acción que has solicitado ha caducado, favor de volver a solicitar el cambio de password',

  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.LOGIN_BLOQUEADO}-title`]: 'Usuario bloqueado',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.LOGIN_BLOQUEADO}`]: 'Excedio el número de intentos fallidos, ingrese a olvidé mi contraseña para reestablecerlo',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.LOGIN_EXPIRADO}-title`]: 'Contaseña expirada',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.LOGIN_EXPIRADO}`]: 'Su contraseña ha expirado, ingrese a olvidé mi contraseña para reestablecerlo',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.LOGIN_RESET}-title`]: 'Contaseña en reestablecimiento',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${auth.LOGIN_RESET}`]: 'Termine el reestablecimiento de su contraseña para poder ingresar',

  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${usuarios.CREATE_USER_ASYNC}-title`]: 'Creación de usuarios',
  [`${SITE_MESSAGE.TYPE.ERROR.HTTP}${usuarios.CREATE_USER_ASYNC}`]: 'Problemas al generar el usuario nuevo, verifique los datos y que el usuario no exista',
}

export default {
  data,
}
