import { all, takeEvery, call, put } from 'redux-saga/effects'
import { reject, equals, compose } from 'ramda'
import {
  loadRetryErrorMessage,
  tableTransactions,
  markTransactions,
  retryTransactions,
  reportTransactions,
} from 'services/retries'

import { getConsulterCommerce } from 'services/commercePublic'

import { safeSagaRequest } from 'helpers/safeSaga'
import actions, {
  setListOfErrorMessages,
  ACTION_LOAD_TRANSACTIONS,
  ACTION_SET_INTEGRATION_DATA,
} from './actions'

const clearEmptyValues = obj => {
  return compose(reject(equals('')), reject(equals(null)), reject(equals(undefined)))(obj)
}

export function* LOAD_ERROR_MESSAGES_LIST({ payload }) {
  const success = yield call(loadRetryErrorMessage, { commerce_id: payload?.commerceId })
  const list = success?.data?.values
  yield put(setListOfErrorMessages(list))
}

export function* GET_COMMERCE_INTEGRATION({ payload }) {
  try {
    yield put(ACTION_LOAD_TRANSACTIONS({ tableLoading: true }))
    const integrationData = yield call(getConsulterCommerce, payload?.type)
    const idIntegration = integrationData?.data?.commerce_id
    yield put(ACTION_SET_INTEGRATION_DATA({ commerce_id: idIntegration, type: payload?.type }))
    const initialData = {
      payload: {
        page: payload.page,
        limit: payload.limit,
        message: payload.message,
        time_zone: payload.time_zone,
        commerce_id: idIntegration,
      },
    }
    yield call(TABLE_TRANSACTION, initialData)
  } catch (error) {
    console.log(error)
  }
}

export function* TABLE_TRANSACTION({ payload }) {
  yield put(ACTION_LOAD_TRANSACTIONS({ tableLoading: true }))
  yield put(ACTION_LOAD_TRANSACTIONS({ tableRetriesFilters: payload }))
  try {
    const success = yield call(tableTransactions, clearEmptyValues(payload))
    const retriesData = success?.data?.values
    yield put(ACTION_LOAD_TRANSACTIONS({ tableTransactions: retriesData }))
  } catch (error) {
    console.log(error)
  }
  yield put(ACTION_LOAD_TRANSACTIONS({ tableLoading: false }))
}

export function* ASYNC_SET_TRANSACTIONS({ payload }) {
  yield put(ACTION_LOAD_TRANSACTIONS({ waitingServiceRespone: true }))
  try {
    const defaultData = {
      commerce_id: payload.commerce_id,
      document_number: payload.document_number,
      pse_cus: payload.pse_cus,
      reference: payload.reference,
      message: payload.message,
      time_zone: payload.time_zone,
    }
    const dataToSend = {
      ...defaultData,
      unique_ids: payload.unique_ids,
    }
    const success = yield call(markTransactions, clearEmptyValues(dataToSend))
    if (success) {
      const dataToRequestTranstactions = {
        payload: {
          ...defaultData,
          limit: payload.limit,
        },
      }
      yield call(TABLE_TRANSACTION, clearEmptyValues(dataToRequestTranstactions))
    }
  } catch (error) {
    console.log(error)
  }
  yield put(ACTION_LOAD_TRANSACTIONS({ waitingServiceRespone: false }))
}

export function* ASYNC_RETRY_TRANSACTIONS({ payload }) {
  yield put(ACTION_LOAD_TRANSACTIONS({ waitingServiceRespone: true }))
  try {
    const defaultData = {
      commerce_id: payload.commerce_id,
      document_number: payload.document_number,
      pse_cus: payload.pse_cus,
      reference: payload.reference,
      message: payload.message,
      time_zone: payload.time_zone,
    }
    const dataToSend = {
      ...defaultData,
      unique_ids: payload.unique_ids,
    }
    const success = yield call(retryTransactions, clearEmptyValues(dataToSend))
    if (success) {
      const dataToRequestTranstactions = {
        payload: {
          ...defaultData,
          limit: payload.limit,
        },
      }
      yield call(TABLE_TRANSACTION, clearEmptyValues(dataToRequestTranstactions))
    }
  } catch (error) {
    console.log(error)
  }
  yield put(ACTION_LOAD_TRANSACTIONS({ waitingServiceRespone: false }))
}

export function* ASYNC_EXPORT_TRANSACTIONS({ payload }) {
  yield put(ACTION_LOAD_TRANSACTIONS({ waitingServiceRespone: true }))
  try {
    const defaultData = {
      commerce_id: payload.commerce_id,
      document_number: payload.document_number,
      pse_cus: payload.pse_cus,
      reference: payload.reference,
      message: payload.message,
      time_zone: payload.time_zone,
    }
    const dataToSend = {
      ...defaultData,
      name_report: payload.reportName,
    }
    const success = yield call(reportTransactions, clearEmptyValues(dataToSend))
    if (success) {
      const dataToRequestTranstactions = {
        payload: {
          ...defaultData,
          limit: payload.limit,
        },
      }
      yield call(TABLE_TRANSACTION, clearEmptyValues(dataToRequestTranstactions))
    }
  } catch (error) {
    console.log(error)
  }
  yield put(ACTION_LOAD_TRANSACTIONS({ waitingServiceRespone: false }))
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.LOAD_COMMERCE_INTEGRATION,
      safeSagaRequest(GET_COMMERCE_INTEGRATION, actions.LOAD_COMMERCE_INTEGRATION),
    ),
    takeEvery(
      actions.RETRIES_GET_ERROR_MESSAGES_LIST,
      safeSagaRequest(LOAD_ERROR_MESSAGES_LIST, actions.RETRIES_GET_ERROR_MESSAGES_LIST),
    ),
    takeEvery(
      actions.TABLE_TRANSACTIONS,
      safeSagaRequest(TABLE_TRANSACTION, actions.TABLE_TRANSACTIONS),
    ),
    takeEvery(
      actions.SET_TRANSACTIONS,
      safeSagaRequest(ASYNC_SET_TRANSACTIONS, actions.SET_TRANSACTIONS),
    ),
    takeEvery(
      actions.RETRY_TRANSACTIONS,
      safeSagaRequest(ASYNC_RETRY_TRANSACTIONS, actions.RETRY_TRANSACTIONS),
    ),
    takeEvery(
      actions.EXPORT_TRANSACTIONS,
      safeSagaRequest(ASYNC_EXPORT_TRANSACTIONS, actions.EXPORT_TRANSACTIONS),
    ),
  ])
}
