import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  loadCities,
  loadPaymentMethods,
  loadCommerceDocuments,
  loadTypeTickets,
  loadPaymentCycle,
  loadTypeAccount,
  loadTypeService,
  loadStatusCommerce,
  loadRoles,
  loadTypeBD,
  loadTypeTopeN,
  loadTypeTopeJ,
  loadCurrencyAllowed,
  loadSubTypeTickets,

} from 'services/general'
import { SITE_MESSAGE } from 'constants/site_message'
import { safeSagaRequest } from 'helpers/safeSaga'
import store from 'models/store'
import { isEmpty } from 'helpers/isEmpty'
import actions from './actions'
import { ACTION_configLoading } from '../config/actions'

export function* LOAD_ASYNC_CITIES() {
  /** Prevent reloading */
  const { general } = store.getState()
  if (!isEmpty(general.cities)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadCities)
  yield put({
    type: actions.LOAD_CITIES,
    payload: success.data,
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_PAYMENTMETHODS() {
  /** Prevent reloading */
  const { general } = store.getState()
  if (!isEmpty(general.paymentMethods)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadPaymentMethods)
  yield put({
    type: actions.LOAD_PAYMENTMETHODS,
    payload: success.data,
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_COMMERCEDOCUMENTS() {
  const { general } = store.getState()
  if (!isEmpty(general.commerceDocuments)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadCommerceDocuments)
  yield put({
    type: actions.LOAD_COMMERCEDOCUMENTS,
    payload: success.data,
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_TYPETICKETS() {
  /** Prevent reloading */
  const { general } = store.getState()
  if (!isEmpty(general.typeTickets)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadTypeTickets)
  yield put({
    type: actions.LOAD_TYPETICKETS,
    payload: success.data,
  })

  const success2 = yield call(loadSubTypeTickets)
  yield put({
    type: actions.LOAD_CATALOG_GENERAL,
    payload: { subtypeTicket: success2.data },
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_TYPETOPEN() {
  /** Prevent reloading */
  const { general } = store.getState()
  if (!isEmpty(general.typeTopeN)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadTypeTopeN)
  let lista = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  if (success.data.length > 0) {
    const val = success.data[0].name
    lista = val.split(',')
  }
  const topes = []
  lista.forEach(element => {
    const valT = {
      code: Number(element),
      id: Number(element),
      name: Number(element),
      type: 'tope-natural',
    }
    topes.push(valT)
  })
  yield put({
    type: actions.LOAD_TYPETOPEN,
    payload: topes,
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_TYPETOPEJ() {
  /** Prevent reloading */
  const { general } = store.getState()
  if (!isEmpty(general.typeTopeJ)) return
  /** Load */
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadTypeTopeJ)
  let lista = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  if (success.data.length > 0) {
    const val = success.data[0].name
    lista = val.split(',')
  }
  const topes = []
  lista.forEach(element => {
    const valT = {
      code: Number(element),
      id: Number(element),
      name: Number(element),
      type: 'tope-jyridico',
    }
    topes.push(valT)
  })
  yield put({
    type: actions.LOAD_TYPETOPEJ,
    payload: topes,
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_PAYMENTCYCLE() {
  const { general } = store.getState()
  if (!isEmpty(general.paymentCycle)) return
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadPaymentCycle)
  yield put({
    type: actions.LOAD_CATALOG_GENERAL,
    payload: { paymentCycle: success.data },
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_TYPETACCOUNT() {
  const { general } = store.getState()
  if (!isEmpty(general.typeAccount)) return
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadTypeAccount)
  yield put({
    type: actions.LOAD_CATALOG_GENERAL,
    payload: { typeAccount: success.data },
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_TYPESERVICE() {
  const { general } = store.getState()
  if (!isEmpty(general.typeService)) return
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadTypeService)
  yield put({
    type: actions.LOAD_CATALOG_GENERAL,
    payload: { typeService: success.data },
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_STATUSCOMMERCE() {
  const { general } = store.getState()
  if (!isEmpty(general.statusCommerce)) return
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadStatusCommerce)
  yield put({
    type: actions.LOAD_CATALOG_GENERAL,
    payload: { statusCommerce: success.data },
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export function* LOAD_ASYNC_ROLES() {
  //const { general } = store.getState()
  //if (!isEmpty(general.roles)) return
  try {
    yield put(ACTION_configLoading({ loading: true }))
    const success = yield call(loadRoles)
    yield put({
      type: actions.LOAD_CATALOG_GENERAL,
      payload: { roles: success.data },
    })
    yield put(
      ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
    )
  } catch (error) {
    console.log(error)
  }
}

export function* LOAD_ASYNC_TYPEBD() {
  const { general } = store.getState()
  if (!isEmpty(general.type_bd)) return
  try {
    yield put(ACTION_configLoading({ loading: true }))
    const success = yield call(loadTypeBD)
    yield put({
      type: actions.LOAD_CATALOG_GENERAL,
      payload: { type_bd: success.data },
    })
    yield put(
      ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
    )
  } catch (error) {
    console.log(error)
  }
}

export function* LOAD_ASYNC_CURRENCY_ALLOWED() {
  const { general } = store.getState()
  if (!isEmpty(general.typeCurrencyAllowed)) return
  yield put(ACTION_configLoading({ loading: true }))
  const success = yield call(loadCurrencyAllowed)
  yield put({
    type: actions.LOAD_CURRENCY_ALLOWED,
    payload: success.data,
  })
  yield put(
    ACTION_configLoading({ loading: false, message: { type: SITE_MESSAGE.TYPE.SUCCESS.HTTP } }),
  )
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      actions.LOAD_ASYNC_CITIES,
      safeSagaRequest(LOAD_ASYNC_CITIES, actions.LOAD_ASYNC_CITIES),
    ),
    takeEvery(
      actions.LOAD_ASYNC_PAYMENTMETHODS,
      safeSagaRequest(LOAD_ASYNC_PAYMENTMETHODS, actions.LOAD_ASYNC_PAYMENTMETHODS),
    ),
    takeEvery(
      actions.LOAD_ASYNC_COMMERCEDOCUMENTS,
      safeSagaRequest(LOAD_ASYNC_COMMERCEDOCUMENTS, actions.LOAD_ASYNC_COMMERCEDOCUMENTS),
    ),
    takeEvery(
      actions.LOAD_ASYNC_TYPETICKETS,
      safeSagaRequest(LOAD_ASYNC_TYPETICKETS, actions.LOAD_ASYNC_TYPETICKETS),
    ),
    takeEvery(
      actions.LOAD_ASYNC_PAYMENTCYCLE,
      safeSagaRequest(LOAD_ASYNC_PAYMENTCYCLE, actions.LOAD_ASYNC_PAYMENTCYCLE),
    ),
    takeEvery(
      actions.LOAD_ASYNC_TYPESERVICE,
      safeSagaRequest(LOAD_ASYNC_TYPESERVICE, actions.LOAD_ASYNC_TYPESERVICE),
    ),
    takeEvery(
      actions.LOAD_ASYNC_TYPETACCOUNT,
      safeSagaRequest(LOAD_ASYNC_TYPETACCOUNT, actions.LOAD_ASYNC_TYPETACCOUNT),
    ),
    takeEvery(
      actions.LOAD_ASYNC_STATUSCOMMERCE,
      safeSagaRequest(LOAD_ASYNC_STATUSCOMMERCE, actions.LOAD_ASYNC_STATUSCOMMERCE),
    ),
    takeEvery(
      actions.LOAD_ASYNC_ROLES,
      safeSagaRequest(LOAD_ASYNC_ROLES, actions.LOAD_ASYNC_ROLES),
    ),
    takeEvery(
      actions.LOAD_ASYNC_TYPEBD,
      safeSagaRequest(LOAD_ASYNC_TYPEBD, actions.LOAD_ASYNC_TYPEBD),
    ),
    takeEvery(
      actions.LOAD_ASYNC_TYPETOPEN,
      safeSagaRequest(LOAD_ASYNC_TYPETOPEN, actions.LOAD_ASYNC_TYPETOPEN),
    ),
    takeEvery(
      actions.LOAD_ASYNC_TYPETOPEJ,
      safeSagaRequest(LOAD_ASYNC_TYPETOPEJ, actions.LOAD_ASYNC_TYPETOPEJ),
    ),
    takeEvery(
      actions.LOAD_ASYNC_CURRENCY_ALLOWED,
      safeSagaRequest(LOAD_ASYNC_CURRENCY_ALLOWED, actions.LOAD_ASYNC_CURRENCY_ALLOWED),
    ),
  ])
}
