const actions = {
  LOAD: 'transactions/LOAD',
  TABLE_TRANSACTIONS: 'transactions/TABLE_TRANSACTIONS',
}

export const ACTION_LOAD_TRANSACTIONS = (transactionsData = []) => ({
  type: actions.LOAD,
  payload: transactionsData,
})

export const tableTransactionsData = data => ({
  type: actions.TABLE_TRANSACTIONS,
  payload: { ...data },
})

export default actions
