import actions from './actions'

/**
 * Referirse a src/assets/json/commerce.json para revisar estructura de array
 */

const initialState = {
  usuarios: [],
  userSelect: {},
  commerces: [],
  loading: false,
  success: false,
  onError: false,
}

export default function usuariosReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_USER: {
      const { payload = [] } = action
      return {
        ...state,
        userSelect: payload,
      }
    }
    case actions.CHANGE_VALUE: {
      const { payload = {} } = action
      return { ...state, ...payload }
    }
    default:
      return state
  }
}
