const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  RETRIEVING: 'user/RETRIEVE',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGOT: 'user/FORGOT',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  LOGIN_BLOQUEADO: 'user/LOGIN_BLOQUEADO',
  LOGIN_EXPIRADO: 'user/LOGIN_EXPIRADO',
  LOGIN_RESET: 'user/LOGIN_RESET',
  TOKEN_RESET: 'user/TOKEN_RESET',
}

export const ACTION_Login = ({ email, password }) => ({
  type: actions.LOGIN,
  payload: {
    email,
    password,
  },
})

export const ACTION_Forgot = ({ email, captcha }) => ({
  type: actions.FORGOT,
  payload: {
    email,
    captcha,
  },
})

export const ACTION_ChangePassword = ({ identifier, password, captcha }) => ({
  type: actions.CHANGE_PASSWORD,
  payload: {
    identifier,
    password,
    captcha,
  },
})

export const ACTION_TokenReset = () => ({
  type: actions.TOKEN_RESET,
})

export const ACTION_Logout = () => ({
  type: actions.LOGOUT,
})

export default actions
